import React from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import CultureCard from './components/CultureCard';
import { EstablishmentAnalytics } from '../../../../store/kcApi';

interface CultureProps {
  analytics?: EstablishmentAnalytics;
  isLoading?: boolean;
}

const Culture: React.FC<CultureProps> = ({ analytics, isLoading }) => {
  {if (!analytics) return null;}
  {if (isLoading) return (
    <Stack spacing={2}>
      <Skeleton variant="rounded" width='40vw' height={40} />
      <Skeleton variant="rounded" width='40vw' height={40} />
      <Skeleton variant="rounded" width='40vw' height={40} />
    </Stack>
  )}

  return (
    <Box>
      <Typography 
        variant='subtitle2' 
        sx={{
          textTransform: 'uppercase',
          color: '#837A78',
          fontSize: '20px',
          padding: '0px 0px 20px 0px',
        }}
      >
        Culture
      </Typography>
      <Stack spacing={2}>
        <CultureCard 
          title="Schedule flexibility"
          description="How easily employees can change their work hours or shifts to fit their lives"
          value={analytics.schedule_flexibility || 3}
          marks={[{value: 1, label: "Not flexible"},{value: 5, label: "Very Flexible"}]}
        />
        <CultureCard 
          title="Employee satisfaction"
          description="How likely current or past employees are to recommend this establishment for work"
          value={analytics.employee_satisfaction || 3}
          marks={[{value: 1, label: "Don’t recommend establishment"},{value: 5, label: "Highly recommend establishment"}]}
        />
        <CultureCard 
          title="Approval of management"
          description="How likely current or past employees are to approve of management"
          value={analytics.management_evaluation || 3}
          marks={[{value: 1, label: "Don’t approve"},{value: 5, label: "Highly approve of"}]}
        />
      </Stack>
    </Box>
  );
};

export default Culture;
