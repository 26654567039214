import { Box, Chip, Grid, Skeleton, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { type } from 'os';
import React from 'react';
import { EnumType } from 'typescript';

import { ReactComponent as IncreaseArrow } from '../../../../assets/svg/icons/increase_arrow.svg';
import { ReactComponent as DecreaseArrow } from '../../../../assets/svg/icons/decrease_arrow.svg';
import { ReactComponent as AvgArrow } from '../../../../assets/svg/icons/avg_arrow.svg';
import { Link } from 'react-router-dom';


export enum StatsComparisonType {
  GREATER_THAN = "higher",
  LESS_THAN = "lower",
  AVG = "average",
  UNKNOWN = "unknown",
}

export interface CustomStatsProps {
  avgHourlyWage: number;
  avgWageComparison: StatsComparisonType;
  avgTipsPerShift: number;
  avgTipsComparison: StatsComparisonType;
  tipPooling: boolean;
  avgShiftsPerWeek: number;
  avgShiftsComparison: StatsComparisonType;
  healthScore: string;
  wageTheftsReported: boolean;
  wageTheftsLink: string;
  isLoading?: boolean;
}

enum StatsCardType {
  DOLLAR_VALUE,
  QUANTITY,
  EMPTY,
}


interface StatsCardProps {
  value: string;
  description: string;
  type: StatsCardType;
  comparisonType: StatsComparisonType;
  isMobile: boolean;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  padding: '5px 12px 5px 12px',
  borderRadius: '10px',
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  textAlign: 'center',
}));

const ComparisonChip: React.FC<{ comparisonType: StatsComparisonType, isMobile: boolean }> = ({
  comparisonType, isMobile }) => {
  switch (comparisonType) {
    case StatsComparisonType.AVG:
      return (
        <StyledChip
          icon={<AvgArrow />}
          label={isMobile ? 'Average' : 'Avg for area'}
          sx={{ backgroundColor: "warning.light" }} />
      );
    case StatsComparisonType.GREATER_THAN:
      return (
        <StyledChip
          icon={<IncreaseArrow />}
          label={isMobile ? 'High' : 'Higher than nearby'}
          sx={{ backgroundColor: "success.light" }} />
      );
    case StatsComparisonType.LESS_THAN:
      return (
        <StyledChip
          icon={<DecreaseArrow />}
          label={isMobile ? 'Low' : 'Lower than nearby'}
          sx={{ backgroundColor: "error.light" }} />
      );
    default:
      return null;
  }
}

const StatsCard: React.FC<StatsCardProps> = (props) => {
  return (
    <Box sx={{
      backgroundColor: "#FFFFFF",
      padding: '20px',
      borderRadius: '12px',
      // height: '100%'
    }}>
      <Typography variant='body1' color='text.secondary'>{props.description}</Typography>
      <Typography variant='body1' color='text.secondary' sx={{
        fontWeight: 'bold',
        padding: '5px 0px',
        fontSize: props.isMobile ? '42px' : '60px',
      }}>{props.type == StatsCardType.DOLLAR_VALUE && "$"}{props.value}</Typography>
      <ComparisonChip comparisonType={props.comparisonType} isMobile={props.isMobile} />
    </Box>
  );
}

interface InfoCardProps {
  title: string;
  value: string;
  valueColor?: string;
  isMobile: boolean;
}

const InfoCard: React.FC<InfoCardProps> = ({ title, value, valueColor = 'text.secondary', isMobile }) => {
  return (
    <Box sx={{
      backgroundColor: "#FFFFFF",
      padding: '20px',
      borderRadius: '12px',
      display: isMobile ? 'grid' : 'flex',
      height: '100%',
    }}>
      <Typography variant='body1' color='text.secondary'>{title}:</Typography>
      <Typography variant='body1' color={valueColor} sx={{
        fontWeight: 'bold',
        padding: '0px 0px 0px 5px',
      }}>{value}</Typography>
    </Box>
  );
}

const WrapOrNot: React.FC<{ condition: boolean, wrapper: React.FC, children: React.ReactElement }> =
  ({ condition, wrapper, children }) => condition ? wrapper(children) : children;


const CustomStats: React.FC<CustomStatsProps> = ({
  avgHourlyWage,
  avgWageComparison,
  avgTipsPerShift,
  avgTipsComparison,
  tipPooling,
  avgShiftsPerWeek,
  avgShiftsComparison,
  healthScore,
  wageTheftsReported,
  wageTheftsLink,
  isLoading
}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Typography
        variant='subtitle2'
        sx={{
          textTransform: 'uppercase',
          color: '#837A78',
          fontSize: '20px',
          padding: '0px 0px 20px 0px',
        }}
      >
        Quick Stats
      </Typography>
      {isLoading ? (
        <Stack direction={{ xs: 'row', sm: 'column' }} spacing={2} useFlexGap flexWrap="wrap">
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton variant="rectangular" width={210} height={118} />
          <Skeleton variant="rectangular" width={210} height={118} />
        </Stack>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6} md={12}>
            <StatsCard
              value={avgHourlyWage > 0 ? avgHourlyWage.toFixed(0).toString() : "--"}
              description={isMobile ? 'Avg. hourly wage' : 'Average hourly wage'}
              type={avgHourlyWage > 0 ? StatsCardType.DOLLAR_VALUE : StatsCardType.EMPTY}
              comparisonType={avgHourlyWage > 0 ? avgWageComparison : StatsComparisonType.UNKNOWN}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <StatsCard
              value={avgTipsPerShift > 0 ? avgTipsPerShift.toFixed(0).toString() : "--"}
              description={isMobile ? 'Avg. tips per shift' : 'Average tips per shift'}
              type={avgTipsPerShift > 0 ? StatsCardType.DOLLAR_VALUE : StatsCardType.EMPTY}
              comparisonType={avgTipsPerShift > 0 ? avgTipsComparison : StatsComparisonType.UNKNOWN}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <StatsCard
              value={avgShiftsPerWeek > 0 ? avgShiftsPerWeek.toFixed(0).toString() : "--"}
              description={isMobile ? 'Avg. shifts per week' : 'Average shifts per week'}
              type={StatsCardType.QUANTITY}
              comparisonType={avgShiftsPerWeek > 0 ? avgShiftsComparison : StatsComparisonType.UNKNOWN}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <Stack direction="column" spacing={2}>
              <InfoCard
                title='Tip pooling'
                value={tipPooling ? 'Yes' : 'No'}
                isMobile={isMobile}
              />
              <InfoCard
                title='Sanitation Score'
                value={healthScore}
                valueColor='#000AFF'
                isMobile={isMobile}
              />
              <WrapOrNot
                condition={wageTheftsReported}
                wrapper={children => <Link to={wageTheftsLink}>{children as React.ReactElement}</Link>}>
                <InfoCard
                  title='Reports of wage thefts'
                  value={wageTheftsReported ? 'Yes' : 'No'}
                  valueColor={wageTheftsReported ? '#E44444' : undefined}
                  isMobile={isMobile}
                />
              </WrapOrNot>
            </Stack>
          </Grid>
        </Grid>
      )
      }
    </Box >
  );
};

export default CustomStats;
