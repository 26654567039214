import React from 'react';
import Box from '@mui/material/Box';
import { Rating, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import KCStarRating from '../../../../components/KCStarRating';

interface FakeReviewCardProps {
  stars: number;
  reviewText: string;
  baseRate: number;
  avgTips: number;
  sanitationScore: string;
}

const FakeReviewCard: React.FC<FakeReviewCardProps> = ({
  stars,
  reviewText,
  baseRate,
  avgTips,
  sanitationScore,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Box sx={{
      maxWidth: '722px'
    }}>
      <Stack>
        <Box sx={{
          border: '1px solid #FBF8F5',
          borderRadius: '18px 18px 0px 0px',
          borderBottom: 'none',
          padding: '24px',
          [theme.breakpoints.down('md')]: {
            padding: '12px',
          },
        }}>
          <Stack direction={'row'} spacing={2} alignItems="center">
              <KCStarRating 
              value={stars}
              variant='light'
              readOnly
              />
            <Typography variant="body2" color="#FBF8F5">{stars} stars</Typography>
          </Stack>
        </Box>
        <Box 
        sx={{
          border: '1px solid #FFFFFF',
          borderRadius: '0px 0px 18px 18px',
          padding: '32px',
          [theme.breakpoints.down('md')]: {
            padding: '16px',
          },
        }}>
          <Typography variant="body2" color="#FBF8F5">"{reviewText}"</Typography>
          <Stack direction="row" spacing={6} sx={{ marginTop: '32px'}}>
            <Box>
              <Typography variant="body1" color="#FBF8F5" fontSize={!isMobile ? 18: 12}>Base Rate</Typography>
              <Typography variant="h4"  color="#FBF8F5" fontWeight={700}>${baseRate}<span style={{fontSize: '14px' }} >/hr</span></Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="#FBF8F5" fontSize={!isMobile ? 18: 12}>Avg. tips</Typography>
              <Typography variant="h4" color="#FBF8F5" fontWeight={700}>${avgTips}</Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="#FBF8F5" fontSize={!isMobile ? 18: 12}>Sanitation score</Typography>
              <Typography variant="h4" color="#FBF8F5" fontWeight={700}>{sanitationScore}</Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default FakeReviewCard;

