import { Box, Container, Grid, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import KCButtonBase from '../../components/Button';
import happyCoffeeCup from '../../assets/images/happy-coffee-cup.png';
import FrequentlyAskedQuestions from './components/FrequentlyAskedQuestions';
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';

import faqsData from '../../assets/faq.json';

const AboutUsPage: React.FC = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  function writeYourReview() {
    ReactGA.event({
      category: "User Interaction",
      action: "Click",
      label: "Write Review"
    })
    navigate("/write-review");
  }
  const faqs = faqsData.faqs as { question: string; answer: string }[];
  return (
    <Box>
      <Box sx={{
        backgroundColor: '#FBF8F5',
        padding: '58px 0px'
      }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} order={{ xs: 2, md: 1 }}>
              <Typography variant="h2" color="text.secondary" textAlign="center">About us</Typography>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} display="flex" justifyContent="center">
              <Box
                component="img"
                alt="Happy Coffee Cup"
                src={happyCoffeeCup}
                width={{
                  xs: "225px",
                  md: "320px"
                }}
                height={{
                  xs: "253px",
                  md: "358px"
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} order={3} display="flex" alignItems="center">
              <Typography variant="body1" color="text.secondary">
                Kitchen Confidentials exists to empower workers in the food service industry. Kitchen Confidentials grew out of a need for a platform where food service workers can access trustworthy information regarding the compensation, workplace culture, and benefits offered by establishments in New York City.<br /><br />

                Kitchen Confidentials is committed to fostering positive change in the food service industry. We celebrate businesses that prioritize the well-being of their employees, and create accountability among businesses that could improve worker treatment. We believe in the power of transparency to create an industry where owners, managers, waiters, line cooks, dishwashers, and customers can all thrive. <br /><br />
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{
        backgroundColor: '#3E3938',
        padding: '30px 0px',
        width: '100%',
        minHeight: '390px',
      }}>
        <Container>
          <Stack justifyContent='center' alignItems='center' spacing={4}>
            <Typography variant="h4" color="#FBF8F5" textAlign="center" sx={{
              paddingTop: '50px',
              fontWeight: 350,
            }}>
              Your voice matters. Help us with our mission by leaving a review – we’re all <br />in this together.
            </Typography>
            <KCButtonBase
              variant="contained"
              sx={{ width: '382px' }}
              onClick={writeYourReview}
            >
              Write your review
            </KCButtonBase>
          </Stack>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: '#F1E8DE', padding: '58px 0px' }}>
        <Container>
          <Typography variant="h1" color="text.secondary" textAlign="center" sx={{ padding: '50px 50px 100px 50px' }}>FAQ</Typography>
          <FrequentlyAskedQuestions faqData={faqs} />
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUsPage;