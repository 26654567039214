import { Button, ButtonProps, Rating, RatingProps, styled } from '@mui/material';
import React, { useState } from 'react';

import { ReactComponent as FilledStar } from '../../assets/svg/Star/star_fill_full.svg';
import { ReactComponent as PartialStar } from '../../assets/svg/Star/star_fill_half.svg';
import { ReactComponent as EmptyStar } from '../../assets/svg/Star/star_fill_empty.svg';
import { ReactComponent as FilledStarDark } from '../../assets/svg/Star/star_fill_full_dark.svg';
import { ReactComponent as PartialStarDark } from '../../assets/svg/Star/star_fill_half_dark.svg';
import { ReactComponent as EmptyStarDark } from '../../assets/svg/Star/star_fill_empty_dark.svg';

import './style.css';

const KCStarStyled = styled(Rating)(({ theme }) => ({
}));

const IconContainer = (props: any) => {
  const { value, ...other } = props;
  // Here you can determine which icon to use based on the value
  let Icon;
  if (value >= 1) {
    Icon = FilledStar;
  } else if (value > 0) {
    Icon = PartialStar;
  } else {
    Icon = EmptyStar;
  }

  return <Icon {...other} />;
};


const KCStarRating: React.FC<RatingProps & { variant: string }> = (props) => {
  const { variant: variant, ...otherProps } = props;

  return (
    <KCStarStyled
      {...otherProps}
      getLabelText={(value: number) => `${value} Star${value !== 1 ? 's' : ''}`}
      // precision={0.5}
      icon={ variant == "dark" ? <FilledStarDark style={{ width: '100%', height: '100%'}} /> : <FilledStar className='custom-icon'/>}
      emptyIcon={variant == "dark" ? <EmptyStarDark style={{ width: '100%', height: '100%'}}/> : <EmptyStar className='custom-icon'/>}
      
       // Apply custom and passed styles
    />
  );
}

export default KCStarRating;