import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Skeleton,
  Stack, Typography,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import EstablishmentFilterCard, {
  EstablishmentFilterCardProps,
} from "../EstablishmentFilterCard";
import { ReactComponent as CoffeeLogo } from "../../../../assets/svg/coffee_logo.svg";
import { ReactComponent as ExitIcon } from '../../../../assets/svg/icons/exit_icon_dark.svg';
import { useAppSelector } from "../../../../hooks/hooks";
import { selectCurrentMapState, setMapState } from "../../../../store/mapSlice";
import { grey } from "@mui/material/colors";
import { selectCurrentFilterState, setFilterState } from "../../../../store/filterSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface FilterCardProps {
  data: EstablishmentFilterCardProps[];
  totalItems: number;
  isLoading: boolean;
}

const Puller = styled("div")(({ theme }) => ({
  width: 50,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 25px)",
}));

const ResponsiveContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const mapState = useAppSelector(selectCurrentMapState);
  const filterState = useAppSelector(selectCurrentFilterState);

  const isListVisible = mapState?.isListVisible;

  const [drawerOpen, setDrawerOpen] = useState(isListVisible);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    dispatch(setFilterState({ submitSearch: false }))
    dispatch(setMapState({ isListVisible: false }));
  };

  if (isMobile) {
    return (
      <>
        {drawerOpen && (
          <Box
            sx={{
              position: 'relative',
              backgroundColor: "#FFFFFF",
              borderRadius: "20px 20px 0 0",
            }}
          >
            <IconButton sx={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleDrawerClose}>
              <ExitIcon width={16} height={16} />
            </IconButton>
            <Box
              position='fixed'
              bottom='20px'
              left='60px'
              right='60px'
              padding='6px'
              display="flex"
              justifyContent='center'
              zIndex={100}
            >
              <Box
                bgcolor="common.white"
                color="common.black"
                padding='4px 12px'
                borderRadius='8px'
                boxShadow="0px 1px 6px rgba(0, 0, 0, 0.25)"
                sx={{
                  cursor: 'pointer',
                }}
                display="flex"
                gap='8px'
                onClick={() => {
                  dispatch(setMapState({ isListVisible: false }));
                }}
              >
                <Typography>Map View</Typography>
                <MapOutlinedIcon />
              </Box>
            </Box>
            <Puller onClick={handleDrawerClose} />
            {children}
          </Box>
        )}
      </>
    );
  }

  return (
    <Stack
      sx={{
        height: "750px",
        backgroundColor: "#FFFFFF",
      }}
    >
      {children}
    </Stack>
  );
};

const FilterCards: React.FC<FilterCardProps> = ({
  data,
  totalItems,
  isLoading,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const filterState = useAppSelector(selectCurrentFilterState);

  const containerRef = useRef<null | HTMLDivElement>(null);
  const { selectedMapMarkerEstId }: any = useAppSelector(selectCurrentMapState);

  const numberOfEstablishments = 10;
  const [page, setPage] = useState(1);

  const startIndex = (page - 1) * numberOfEstablishments;
  const endIndex = Math.min(startIndex + numberOfEstablishments, data.length);
  const slicedData = data.slice(startIndex, endIndex);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    const newSelectedIndex = data.findIndex(item => item.estId === selectedMapMarkerEstId);
    setSelectedIndex(newSelectedIndex !== -1 ? newSelectedIndex : null);
  }, [data, selectedMapMarkerEstId]);

  const getFocusedPage = () => {
    if (selectedIndex !== null) {
      const focusedPageIndex = Math.floor(selectedIndex / numberOfEstablishments);
      return focusedPageIndex + 1;
    }
    return 1;
  };

  const scrollToFocusedItem = () => {
    if (!isMobile && selectedIndex !== null) {
      const focusedItemIndex = selectedIndex % numberOfEstablishments;
      const focusedItemElement = containerRef.current?.children[focusedItemIndex];
      const scrollTop = focusedItemIndex * (focusedItemElement?.getBoundingClientRect().height ?? 0);

      containerRef.current?.scrollTo({ top: scrollTop, behavior: "smooth" });
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    const containerTop = containerRef.current?.getBoundingClientRect().top ?? 0;
    containerRef.current?.scrollTo({ top: containerTop, behavior: "smooth" });
  };

  useEffect(() => {
    setPage(1);
  }, [data]);

  useEffect(() => {
    if (!isMobile) {
      const focusedPage = getFocusedPage();
      setPage(focusedPage);
      setTimeout(() => scrollToFocusedItem(), 1000);
    }

  }, [selectedIndex]);

  if (isLoading) {
    const iterations = Array.from({ length: 2 });

    return (
      <Stack spacing={4} p={2}>
        <Skeleton variant="text" width={100} sx={{ fontSize: "2rem" }} />
        {iterations.map((_, index) => (
          <Stack spacing={2} key={index}>
            <Grid container>
              <Grid item xs={4} md={4}>
                <Skeleton
                  variant="rounded"
                  sx={{
                    width: { xs: 100, md: 143 },
                    height: { xs: 100, md: 143 }
                  }}
                />
              </Grid>
              <Grid item xs={8} md={8}>
                <Stack>
                  <Skeleton variant="rectangular" width="50%" height={26} />
                  <Skeleton variant="text" width="50%" sx={{ fontSize: "4rem" }} />
                  <Skeleton variant="text" width="80%" sx={{ fontSize: "1rem" }} />
                </Stack>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={6}>
              <Box width="100%">
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
              </Box>
              <Box width="100%">
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
              </Box>
              <Box width="100%">
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
              </Box>
            </Stack>
            <Skeleton variant="rectangular" height={48} />
          </Stack>
        ))}
      </Stack>
    );
  }

  if (!data || data.length === 0) {
    const search = filterState?.name || "";

    return (
      <Box
        sx={{
          height: "750px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Stack spacing={6} p={5} alignItems="center" justifyContent="center">
          <Typography variant="h6" color="text.secondary">
            No results
          </Typography>
          <CoffeeLogo />
          <Typography variant="body1" color="text.secondary" sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/write-review?search=${search}`)}
          >
            Try broadening your search.
          </Typography>
        </Stack>
      </Box>
    );
  }

  return (
    <>
      <ResponsiveContainer>
        <Box
          padding="24px 16px 8px"
          borderBottom="1px solid"
          borderColor="divider"
        >
          <Typography
            variant="body1"
            color={theme.palette.text.secondary}
          >{`${data.length} results`}</Typography>
        </Box>
        <Box
          ref={containerRef}
          sx={{
            overflowY: "auto",
            "::-webkit-scrollbar": {
              width: "10px",
            },
            "::-webkit-scrollbar-track": {
              background: "#ffffff",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#D9D9D9",
              borderRadius: "4px",
              height: "100px",
            },
          }}
        >
          {slicedData.map((item, idx) => (
            <EstablishmentFilterCard
              key={item.estId}
              name={item.name}
              address={item.address}
              baseRate={item.baseRate}
              avgTips={item.avgTips}
              distance={item.distance}
              sanitationScore={item.sanitationScore}
              image={item.image}
              starRating={item.starRating}
              numOfReviews={item.numOfReviews}
              estId={item.estId}
              focused={selectedIndex !== null && idx === selectedIndex % numberOfEstablishments}
            />
          ))}
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            count={Math.ceil(totalItems / numberOfEstablishments)}
            page={page}
            size="medium"
            onChange={handleChangePage}
          />
        </Box>
      </ResponsiveContainer>
    </>
  );
};

export default FilterCards;
