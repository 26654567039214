import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import KCMobileActionDrawer from '../../../../components/KCMobileActionDrawer';
import KCDialog from '../../../../components/KCDialog';
import KCButtonBase from '../../../../components/Button';
import { tr } from '@faker-js/faker';
import { useUpdatePublicReviewMutation } from '../../../../store/kcApi';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

interface ExitDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const ExitDialog: React.FC<ExitDialogProps> = ({ isOpen, onClose }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [updateNewReview] = useUpdatePublicReviewMutation();
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const { reviewId } = useParams<{ reviewId: string }>();
  const navigate = useNavigate();

  const handleExit = async () => {
    if (reviewId) {
      updateNewReview({ reviewId: reviewId, createUpdateReview: { status: 'exited' } })
        .unwrap()
        .then((payload) => {
          localStorage.removeItem('kc_reviews');
          ReactGA.event({
            category: "New Review Form",
            action: "Success Exit New Review",
            label: reviewId,
          });
          navigate(`/establishments/${establishmentId}`);
        })
        .catch((error) => {
          ReactGA.event({
            category: "New Review Form",
            action: "Failed Exit New Review",
            label: reviewId,
          });
          // TODO: error handling
          console.error('Failed to update review', error);
        })
    }
  }

  if (isMobile) {
    return (
      <KCMobileActionDrawer
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
      >
        <Stack spacing={4}  alignItems="center">
          <Typography variant="h2" color="text.secondary">
            Exit Review
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Are you sure you want to exit?
          </Typography>
          <Stack 
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <KCButtonBase kcvariant='outlined' 
              onClick={handleExit} 
              sx={{ width: '80vw' }}
            >
              Yes
            </KCButtonBase>
            <KCButtonBase 
              onClick={onClose} 
              sx={{ width: '80vw' }}
            >
              No
            </KCButtonBase>
          </Stack>
        </Stack>
      </KCMobileActionDrawer>
    )
  }


  return (
    <KCDialog 
      customTitle='Exit Review'
      customContent='Are you sure you want to exit?'
      open={isOpen}
      onClose={onClose}
      actions={
        <Stack 
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          <KCButtonBase kcvariant='outlined' 
            onClick={handleExit} 
            sx={{ width: '158px' }}
          >
            Yes
          </KCButtonBase>
          <KCButtonBase 
            onClick={onClose} 
            sx={{ width: '158px' }}
          >
            No
          </KCButtonBase>
        </Stack>
      }
    />
  );
};

export default ExitDialog;