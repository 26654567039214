import { Button, ButtonProps, styled } from '@mui/material';
import React from 'react';

interface KCButtonProps extends ButtonProps{
  kcvariant?: string;
}

const KCButtonFilled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  height: '48px',
  fontSize: '16px',
  borderRadius: '12px',
  boxShadow: 'none',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 'none',
  },
}));

const KCButtonOutlined = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFF",
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  height: '48px',
  fontSize: '16px',
  borderRadius: '12px',
  boxShadow: 'none',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 'none',
    color: '#FFF'
  },
}));


const KCButtonBase: React.FC<KCButtonProps> = ({ children, ...props }) => {

  switch (props.kcvariant) {
    case 'outlined':
      return (
        <KCButtonOutlined disableRipple {...props}>
          {children}
        </KCButtonOutlined>
      );
    default:
      return (
        <KCButtonFilled disableRipple {...props}>
        {children}
      </KCButtonFilled>
      );
  }
}

export default KCButtonBase;