import { Box, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";

import BaseWage from "../../../../assets/svg/icons/base_wage.svg";
import BaseWageFocus from "../../../../assets/svg/icons/base_wage_focus.svg";
import AvgTips from "../../../../assets/svg/icons/avg_tips.svg";
import AvgTipsFocus from "../../../../assets/svg/icons/avg_tips_focus.svg";
import Sanitation from "../../../../assets/svg/icons/sanitation.svg";
import SanitationFocus from "../../../../assets/svg/icons/sanitation_focus.svg";
import { useAppSelector } from "../../../../hooks/hooks";
import { selectCurrentMapState, setMapState } from "../../../../store/mapSlice";
import { useDispatch } from "react-redux";

export interface IPosition {
  lat: number;
  lng: number;
  id?: number;
  estId?: string;
  totalReviews?: number;
  baseWage?: number;
  avgTips?: number;
  sanitationScore?: string;
  type?: string;
}

const getBackgroundColor = (mode: string | undefined, focus: boolean) => {
  if (mode === "Base Wage") return focus ? "#00CEB6" : "#FFFFFF";
  if (mode === "Avg.tips") return focus ? "#FF00E5" : "#FFFFFF";
  if (mode === "Sanitation") return focus ? "#000AFF" : "#FFFFFF";
}

const getViewModeIcons = (mode: string | undefined, focus: boolean) => {
  if (mode === "Base Wage") return focus ? BaseWageFocus : BaseWage;
  if (mode === "Avg.tips") return focus ? AvgTipsFocus : AvgTips;
  if (mode === "Sanitation") return focus ? SanitationFocus : Sanitation;
};

const getViewValues = (
  mode: string | undefined,
  baseWage: number | undefined,
  avgTips: number | undefined,
  sanitationScore: string | undefined
) => {
  if (mode === "Base Wage") return `$${Math.round(baseWage || 0)}`;
  if (mode === "Avg.tips") return `$${Math.round(avgTips || 0)}`;
  if (mode === "Sanitation") return sanitationScore;
};

const MarkerBox = styled(Box)(({ color }) => ({
  padding: "5px",
  width: "fit-content",
  minWidth: 40,
  borderRadius: "100px",
  display: "flex",
  gap: "6px",
  backgroundColor: `${color}`,
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  top: "-35px",
  left: "-35px",
  filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))",
  cursor: "pointer",
  "&:after": {
    border: `8px solid transparent`,
    borderTopColor: `${color}`,
    content: "''",
    width: 0,
    height: 0,
    left: "calc(50% - 8px)",
    position: "absolute",
    top: "99%",
  },
  "&:hover": {
    cursor: "pointer",
  },
  animation: "bounce 2s ease infinite",
  "@keyframes bounce": {
    "0%, 20%, 50%, 80%, 100%": { transform: "translateY(0)" },
    "40%": { transform: "translateY(-30px)" },
    "60%": { transform: "translateY(-15px)" },
  },
}));

const Marker: React.FC<IPosition> = ({
  lat,
  lng,
  id,
  estId,
  totalReviews,
  baseWage,
  type,
  avgTips,
  sanitationScore,
}) => {
  const dispatch = useDispatch();

  const { animateMarkerId, selectedMapMarkerEstId }: any = useAppSelector(selectCurrentMapState);

  const [isAnimated, setIsAnimated] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  useEffect(() => {
    if (animateMarkerId === estId) {
      setIsAnimated(true);
      setTimeout(() => setIsAnimated(false), 2000);
    }
  }, [animateMarkerId]);

  return (
    <MarkerBox
      color={getBackgroundColor(type, (isHovered || selectedMapMarkerEstId === estId))}
      sx={{ animationPlayState: isAnimated ? "running" : "paused" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(event) => {
        event.stopPropagation();
        setIsClicked(true);
        dispatch(setMapState({ selectedMapMarkerEstId: estId }));
      }}
    >
      <Box
        component="img"
        alt="Type"
        src={getViewModeIcons(type, (isHovered || selectedMapMarkerEstId === estId))}
        width={18}
        height={18}
      />
      <Typography
        variant="caption"
        color={(isHovered || selectedMapMarkerEstId === estId) ? "#FFFFFF" : "text.secondary"}
        fontWeight={600}
        fontSize={12}
      >
        {getViewValues(type, baseWage, avgTips, sanitationScore)}
      </Typography>
    </MarkerBox>
  );
};

export default Marker;