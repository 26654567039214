
import { Box, Button, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useParams } from 'react-router-dom';
import KCShareDrawer from '../../../components/KCShareDrawer';
import KCShareDialog from '../../../components/KCShareDialog';
import KCStarRating from '../../../components/KCStarRating';
import { Delete, East } from '@mui/icons-material';
import KCDialog from '../../../components/KCDialog';
import KCButtonBase from '../../../components/Button';

interface ReviewCardProps {
  review: string;
  rating: number;
  baseRate: number;
  avgTips: number;
  createdDate: string;
  status: string;
  id: string;
  establishmentId: string;
}

const ReviewCard: React.FC<ReviewCardProps> = (props) => {

  const [open, setOpen] = useState(false);
  const [deleteReviewDialog, setDeleteReviewDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const reviewTitle = (text: string) => {
    if (text == '') {
      return "User didn't provide any text!";
    }

    // return the first 6 words of the review
    return text.split(' ').slice(0, 5).join(' ');
  };

  const openReviewClick = () => {
    if (props.status === "completed") {
      window.open(`/establishments/${props.establishmentId}`, '_blank');
    } else {
      window.open(`/establishments/${props.establishmentId}/new-review/${props.id}?existing=true`, '_blank');
    }
  }

  const dateFormat = new Date(props.createdDate);
  const dateOptions: Intl.DateTimeFormatOptions = isMobile ? { year: 'numeric', month: 'short', day: 'numeric' } : { year: 'numeric', month: 'long', day: 'numeric' };
  return (
    <Box sx={{ backgroundColor: "#FFFFFF", padding: '48px' }}>
      <KCDialog
        customTitle="Delete review"
        customContent="Are you sure you want to delete your review? This action is permanent. "
        open={deleteReviewDialog}
        onClose={() => setDeleteReviewDialogOpen(false)}
        actions={
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <KCButtonBase kcvariant='outlined'
              onClick={() => setDeleteReviewDialogOpen(false)}
              sx={{ width: '158px' }}
            >
              Cancel
            </KCButtonBase>
            <KCButtonBase
              onClick={() => {
                setDeleteReviewDialogOpen(false);
              }}
              sx={{ width: '158px' }}
            >
              Delete review
            </KCButtonBase>
          </Stack>
        }
      />
      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems='center'>
            <KCStarRating value={props.rating} variant='dark' readOnly />
            <Typography variant="body2" color="text.secondary">{props.rating} stars</Typography>
          </Stack>
          {props.status === "completed" ?
            <Typography variant="body2" color="text.secondary" fontSize={16}>{dateFormat.toLocaleDateString('en-US', dateOptions)}</Typography>
            :
            <Typography variant="body2" color="text.secondary" fontSize={16} fontWeight={600} bgcolor="#FFF9E5" px="12px" py="6px">{props.status.toUpperCase()}</Typography>
          }
        </Stack>
        <Typography variant="h5" color="text.secondary">
          {reviewTitle(props.review)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.review.slice(0, 200) + "..."}
        </Typography>
        <Box>
          <Stack direction='row' justifyContent="space-between" alignItems="center">
            <Button
              variant="text"
              endIcon={<East />}
              sx={{
                padding: '14px 40px',
                color: 'text.secondary',
                backgroundColor: '#FBF8F5',
                textTransform: 'none',
                '&:hover': {
                  textDecorationColor: '#E44444',
                },
              }}
              onClick={openReviewClick}
            >
              {props.status === "completed" ? "Open review" : `Open ${props.status}`}
            </Button>
            {/* <Button
              variant="text"
              onClick={() => setDeleteReviewDialogOpen(true)}
              endIcon={<Delete />}
              sx={{
                padding: '14px 40px',
                color: 'text.primary',
                textTransform: 'none',
                backgroundColor: '#FBF8F5',
                '&:hover': {
                  textDecorationColor: '#E44444',
                },
              }}
            >
              Delete review
            </Button> */}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default ReviewCard;