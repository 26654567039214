
import { Alert, AlertColor, Box, Container, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import KCButtonBase from '../../components/Button';
import * as yup from 'yup';
import KCTextField from '../../components/KCTextField';
import { useCreateContactUsMutation, CreateContactUsApiArg } from '../../store/kcApi';

const ContactUsPage: React.FC = () => {

  const initalValues = {
    name: '',
    email: '',
    message: '',
  }

  const validationSchema = yup.object().shape({
    name: yup.string().min(5).required('Name is required'),
    email: yup.string().email().required('Email is required'),
    message: yup.string().min(25).required('Message is required'),
  });

  interface alertValues {
    type: AlertColor,
    message: string,
  }


  const [alert, setAlert] = React.useState<alertValues>({} as alertValues);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [createContactUs] = useCreateContactUsMutation();

  const handleSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    const contactRequest: CreateContactUsApiArg = {
      emailContactUs: {
        name: values.name,
        email: values.email,
        message: values.message,
      }
    }
    createContactUs(contactRequest).unwrap()
      .then((response) => {
        setAlert({type: "success", message: "Message sent successfully!"});
        setAlertOpen(true);
        formikHelpers.resetForm();
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        setAlert({type: "error", message: "An error occurred while sending the message!"});
        setAlertOpen(true);
        window.scrollTo(0, 0);
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ 
      backgroundColor: "#FBF8F5",
      paddingBottom: "100px",
    }}>
      <Container>
        {alertOpen && <Alert severity={alert.type} onClose={() => setAlertOpen(false)}>{alert.message}</Alert>}
        <Stack>
          <Typography 
            variant="h3" 
            color="text.secondary" 
            align='center'
            sx={{ 
              padding: "20px 0px 20px 0px",
              fontWeight: 'bold',
            }}
          >Contact</Typography>
          <Box sx={{
            marginTop: "20px",
          }}>
             <Formik
              initialValues={initalValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {formik => (
                <Form>
                  <Stack spacing={4}>
                    <Stack>
                      <Typography variant="body1" color="text.secondary" sx={{fontWeight: "bold", marginBottom: "4px"}}>Name</Typography>
                      <KCTextField 
                        name="name"
                        id="name"
                        variant="outlined"
                        placeholder="Enter your name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        fullWidth
                      />
                    </Stack>
                    <Stack>
                      <Typography variant="body1" color="text.secondary" sx={{fontWeight: "bold",  marginBottom: "4px"}}>Email</Typography>
                      <KCTextField 
                        id="email"
                        name="email"
                        placeholder='Enter your email'
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        fullWidth
                      />
                    </Stack>
                    <Stack>
                      <Typography variant="body1" color="text.secondary" sx={{fontWeight: "bold",  marginBottom: "4px"}}>Message</Typography>
                      <KCTextField
                        id="message"
                        name="message"
                        placeholder='Enter your message...'
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={8}
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.message && Boolean(formik.errors.message)}
                        helperText={formik.touched.message && formik.errors.message}
                      />
                    </Stack>
                  </Stack>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    marginTop: '20px',
                    justifyContent: 'right',
                  }}>
                    <KCButtonBase
                      color="inherit"
                      type="submit"
                      disabled={!formik.isValid}
                      onClick={formik.submitForm}
                      sx={{ mr: 1, width: '181px' }}
                    >
                     Submit
                    </KCButtonBase>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>

        </Stack>
      </Container>
    </Box>
  );
};

export default ContactUsPage;
