
import { Box, Button, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import KCStarRating from '../../../../components/KCStarRating';
import IosShareIcon from '@mui/icons-material/IosShare';
import KCShareDialog from '../../../../components/KCShareDialog';
import { useParams } from 'react-router-dom';
import KCShareDrawer from '../../../../components/KCShareDrawer';

interface ReviewCardProps {
  review: string;
  rating: number;
  baseRate: number;
  avgTips: number;
  createdDate: string;
  wageType: string;
  id: string;
}

const ReviewCard: React.FC<ReviewCardProps> = (props) => {

  const [showMore, setShowMore] = useState(false);
  const [open, setOpen] = useState(false);
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const reviewTitle = (text: string) => {
    if (text == '') {
      return "User didn't provide any text!";
    }

   // return the first 6 words of the review
    return text.split(' ').slice(0, 5).join(' ');

   
  };
  const dateFormat = new Date(props.createdDate);
  const dateOptions: Intl.DateTimeFormatOptions =  isMobile ? { year: 'numeric', month: 'short', day: 'numeric' }  : { year: 'numeric', month: 'long', day: 'numeric' };
  return (
   <Box sx={{ backgroundColor: "#FFFFFF", padding: '20px', borderRadius: '18px'}}>
    {isMobile ? (
        <KCShareDrawer 
        anchor="bottom"
        customTitle='Review' 
        currentUrl={window.location.href}
        open={open} 
        onClose={() => setOpen(false)} 
      />
    ) : ( 
      <KCShareDialog 
        customTitle='Review' 
        currentUrl={window.location.href}
        open={open} 
        onClose={() => setOpen(false)} 
      />
    )}
   
    <Stack direction="column" spacing={2}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" spacing={2} alignItems='center'>
          <KCStarRating value={props.rating} variant='dark' readOnly />
          <Typography variant="body2" color="text.secondary">{props.rating} stars</Typography>
        </Stack>
        <Typography variant="body2" color="text.secondary">{dateFormat.toLocaleDateString('en-US',dateOptions)}</Typography>
      </Box>
      <Typography variant="h5" color="text.secondary">
        {reviewTitle(props.review)}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {props.review}
      </Typography>
      <Box>
        {showMore && (
          <Stack direction='row' spacing={6} sx={{ marginTop: '32px'}}>
            <Box>
              <Typography variant="body1" color="text.secondary">Base Rate</Typography>
              <Typography variant="h4"  color="text.secondary">${props.baseRate.toLocaleString('en-US')}<span style={{fontSize: '14px' }} >/{props.wageType === 'hourly' ? 'hr' : 'yr'}</span></Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="text.secondary">Avg. tips</Typography>
              <Typography variant="h4" color="text.secondary">${props.avgTips}</Typography>
            </Box>
          </Stack>
        )}
        <Stack 
          direction='row'
          justifyContent="space-between"
          alignItems="center"
        >
          <Button 
            variant="text" 
            onClick={() => setShowMore(!showMore)}
            sx={{
              color: 'text.primary',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                textDecorationColor: '#E44444',
              },
            }}
          >
              {showMore ? "Hide ratings" : "Show ratings"}
          </Button>
          <Button 
            variant="text" 
            onClick={() => setOpen(true)}
            startIcon={<IosShareIcon />}
            sx={{
              color: 'text.secondary',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                textDecorationColor: '#E44444',
              },
            }}
            >
              Share
          </Button>
        </Stack>
      </Box>
    </Stack>
   </Box>
  );
};

export default ReviewCard;
