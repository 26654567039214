
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Grid, Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GetPublicEstablishmentsApiArg, useGetPublicEstablishmentsQuery } from '../../store/kcApi';
import Filters from './components/Fiters';
import MapView from './components/MapView';
import { EstablishmentFilterCardProps } from './components/EstablishmentFilterCard';
import { CalculateDistance, isInNYC, useUserLocation } from '../../utils/helpers';
import FilterCards from './components/FilterCards';
import { IPosition } from './components/MapView/Marker';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/hooks';
import { selectCurrentMapState, setMapState } from '../../store/mapSlice';
import { selectCurrentFilterState } from '../../store/filterSlice';
import FilterCardModal from './components/FilterCards/FilterCardModal';
import { useSearchParams } from 'react-router-dom';
import { ScrollToTopOnMount } from '../ScrollToTopOnMount';

interface FilterProps {
  location: string;
  name: string;
}

const ResponsiveContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box>
        {children}
      </Box>
    );
  }

  return (
    <Container>
      {children}
    </Container>
  );
};

const ExplorePage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const mapState = useAppSelector(selectCurrentMapState);
  const filterState = useAppSelector(selectCurrentFilterState);
  const isListVisible = mapState?.isListVisible;

  const { longitude, latitude } = useUserLocation();

  const [searchResults, setSearchResults] = useState<EstablishmentFilterCardProps[]>([]);
  const [searchParams, setSearchParams] = useState<GetPublicEstablishmentsApiArg>({});
  const [locationAlert, setLocationAlert] = useState<boolean>(false);
  const [mapData, setMapData] = useState<IPosition[]>([]);
  const [querySearch, setQuerySearch] = useSearchParams();
  const [search, setSearch] = React.useState<string>(querySearch.get("search") || "");

  const {
    data: establishments,
    error: estErr,
    isLoading: estLoading
  } = useGetPublicEstablishmentsQuery(searchParams);

  // console.log("search params = ", searchParams)
  // console.log("establishments = ", establishments)
  // console.log("search results = ", searchResults)

  const [showLoading, setShowLoading] = useState(estLoading);

  useEffect(() => {
    dispatch(setMapState({ isListVisible: !isMobile }))
  }, [isMobile])

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (estLoading) {
      // Immediately show loading when isLoading is true
      setShowLoading(true);
    } else {

      // Delay the removal of the loading screen
      timer = setTimeout(() => setShowLoading(false), 500); // Adjust 500ms to your needs
    }

    // Cleanup the timer when the component unmounts or isLoading changes
    return () => clearTimeout(timer);
  }, [estLoading]);

  useEffect(() => {
    const { northEastLatitude, northEastLongitude, southWestLatitude, southWestLongitude } = mapState?.bounds || {};
    const lockBounds = mapState?.lockBounds || false;

    if (lockBounds === false) setSearchParams({
      ...searchParams,
      northEastLatitude,
      northEastLongitude,
      southWestLatitude,
      southWestLongitude,
    });
  }, [mapState]);

  useEffect(() => {
    if (!latitude || !longitude) {
      return;
    }
    const isInside = async () => {
      const isInside = await isInNYC({ latitude: latitude, longitude: longitude });
      isInside ? setLocationAlert(false) : setLocationAlert(true);
    };

    isInside()
  }, [latitude, longitude]);

  useEffect(() => {
    if (!establishments?.data) {
      setSearchResults([]);
      setMapData([]);
    }
    if (establishments?.data && !estErr && !estLoading) {
      setSearchResults(establishments?.data?.map((item): EstablishmentFilterCardProps => (
        {
          estId: item.id || "",
          name: item.name || "",
          address: item.address || "",
          baseRate: item.base_rate || 0,
          avgTips: item.avg_tips || 0,
          distance: CalculateDistance(
            { latitude: latitude, longitude: longitude },
            { latitude: item.location?.coordinates?.[1] || 0, longitude: item.location?.coordinates?.[0] || 0 },
          ),
          sanitationScore: item?.health_score === "Not Yet Graded" ? "--" : item?.health_score || "--",
          image: item.photo || "",
          starRating: item.overall_rating || 0,
          numOfReviews: item.total_reviews || 0,
        }
      )) || []);

      setMapData(establishments?.data?.map((item: any): IPosition => {
        return (
          {
            lat: item?.location?.coordinates?.[1] || 0,
            lng: item?.location?.coordinates?.[0] || 0,
            estId: item?.id || "",
            totalReviews: item?.total_reviews || 0,
            baseWage: item?.base_rate || 0,
            avgTips: item?.avg_tips || 0,
            sanitationScore: item?.health_score === "Not Yet Graded" ? "--" : item?.health_score || "--",
            type: item?.name || "",
          }
        );
      }) || []);
    }
  }, [establishments, estLoading]);

  return (
    <Box sx={{
      backgroundColor: '#FBF8F5'
    }}>
      <ScrollToTopOnMount />
      <ResponsiveContainer>
        <Stack>
          <Container>
            <Typography variant={isMobile ? 'h5' : 'h1'} py={!isMobile ? 8 : 2} textAlign='center'>Explore NYC</Typography>
            {!isMobile && locationAlert && (
              <Alert severity="info"
                sx={{
                  margin: "20px 0px",
                }}>
                Looks like your location is outside of NYC! We are currently only serving NYC establishments.
              </Alert>
            )}
          </Container>
          <Filters fieldValue={search} searchParams={searchParams} setSearchParams={setSearchParams} />
          <Grid container>
            {!(isListVisible && isMobile) &&
              <Grid item xs={12} md={isListVisible ? 6 : 12}>
                <MapView markersData={mapData} />
              </Grid>
            }
            {
              isListVisible &&
              <Grid item xs={12} md={6}>
                <FilterCards isLoading={showLoading} data={searchResults} totalItems={establishments?.total_items || 0} />
              </Grid>
            }
          </Grid>
          <FilterCardModal data={searchResults} isLoading={showLoading} />
        </Stack>
      </ResponsiveContainer>
    </Box>
  );
};

export default ExplorePage;
