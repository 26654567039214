import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import { SxProps, Theme, useTheme } from '@mui/material';

interface KCToggleGroupProps extends ToggleButtonGroupProps{
  options: string[];
  value: string;
  changeEvent: (value: string) => void;
}

const KCToggleGroup: React.FC<KCToggleGroupProps> = ({ options, changeEvent, value, ...props }) => {
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = React.useState<string | null>(value);

  const handleOptionChange = (event: React.MouseEvent<HTMLElement>, newOption: string | null) => {
    setSelectedOption(newOption);
    if (newOption) {
      changeEvent(newOption);
    }
  };

  return (
    <ToggleButtonGroup 
      value={selectedOption} 
      exclusive 
      onChange={handleOptionChange} 
      sx={{
        '& .MuiToggleButton-root': {
          border: '1px solid #E4E0DF',
          backgroundColor: 'transparent',
          color: '#837A78',
          fontSize: '14px',
          padding: '5px 20px',
          textTransform: 'none',
          height: '48px',
          borderRadius: '8px',
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#E7E7E7',
            textDecoration: 'underline',
          },
          '&:hover, &.Mui-selected:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: '#E7E7E7',
            textDecoration: 'underline',
          },
        },
      }}
      {...props}

    >
      {options.map((option) => (
        <ToggleButton key={option} value={option}>
          {option}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default KCToggleGroup;
