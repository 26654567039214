import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import GoogleMap from "google-maps-react-markers";
import Marker, { IPosition } from './Marker';
import useSupercluster from 'use-supercluster';
import ClusterMarker from './ClusterMarker';
import { selectCurrentMapState, setMapState } from '../../../../store/mapSlice';
import { useDispatch } from 'react-redux';
import { isInNYC, useUserLocation } from '../../../../utils/helpers';
import { useAppSelector } from '../../../../hooks/hooks';
import { selectCurrentFilterState, setFilterState } from '../../../../store/filterSlice';
import { ReactComponent as UserLocationIcon } from '../../../../assets/svg/icons/user_location.svg';

const Wrapper = styled(Box)({
  width: '100%',
  height: '100%'
})

interface GoogleMapProps {
  position?: IPosition;
  zoom?: number;
  locations?: IPosition[];
  mode?: string;
}

const mapStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

interface UserMarkerProps {
  lat: number;
  lng: number;
}

const UserMarker: React.FC<UserMarkerProps> = ({ lat, lng }) => {
  return (
    <Box sx={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}>
      <UserLocationIcon />
    </Box>
  );
};

const KCGoogleMap: React.FC<GoogleMapProps> = ({
  position,
  locations,
  mode,
}) => {
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState<any>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const mapState = useAppSelector(selectCurrentMapState);
  const filterState = useAppSelector(selectCurrentFilterState);
  const { longitude, latitude } = useUserLocation();

  // const center = useMemo(() => ({
  //   lat: isInNYC({ latitude: latitude, longitude: longitude }) ? latitude : 40.714992,
  //   lng: isInNYC({ latitude: latitude, longitude: longitude }) ? longitude : -73.959613
  // }), [latitude, longitude]);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 40.714992, lng: -73.959613 })
  const defaultZoom = 14;

  useEffect(() => {
    if (!latitude || !longitude) {
      return;
    }

    const updateCenter = async () => {
      const isInsideNYC = await isInNYC({ latitude, longitude });
      if (isInsideNYC) {
        setDefaultCenter(() => ({ lat: latitude, lng: longitude }));
      }
    };

    updateCenter();
  }, [latitude, longitude]);

  const [mapBounds, setMapBounds] = useState({ bounds: [0, 0, 0, 0], zoom: 0 });
  const lockBounds = mapState?.lockBounds || false;

  const mapRef = useRef();

  const points = locations?.filter((location) => {
    return lockBounds || location.totalReviews !== 0;
  }).map((location, idx) => ({
    type: 'Feature',
    properties: {
      id: idx,
      cluster: false,
      estId: location.estId,
      baseWage: location.baseWage,
      avgTips: location.avgTips,
      sanitationScore: location.sanitationScore
    },
    geometry: { type: "Point", coordinates: [location.lng, location.lat] }
  })) || [];

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds: mapBounds.bounds,
    zoom: mapBounds.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const getMapBoounds = (map: any, maps: any, places: any) => {
    const bounds = new maps.LatLngBounds();
    locations?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    return bounds;
  };

  const bindResizeListener = (map: any, maps: any, bounds: any) => {
    map.addListener(
      "idle",
      () => {
        window.addEventListener("resize", () => {
          map.fitBounds(bounds);
        });
      },
      { once: true }
    );
  };

  const findCenter = (markers: { lat: number; lng: number; }[]) => {
    const lats = markers.map(m => m.lat);
    const lngs = markers.map(m => m.lng);
    return {
      lat: (Math.min(...lats) + Math.max(...lats)) / 2,
      lng: (Math.min(...lngs) + Math.max(...lngs)) / 2
    };
  };

  const onLoaded = (map: any, maps: any, places: any) => {
    if (!map || !places?.length) return;

    const shouldCenterOnPlaces = lockBounds === true;
    const shouldResetCenter = !shouldCenterOnPlaces && filterState?.resetFilters;

    if (shouldCenterOnPlaces) {
      const center = findCenter(places);
      const bounds = getMapBoounds(map, maps, locations);
      map.fitBounds(bounds);
      map.setCenter(center);
      if (map.getZoom() > defaultZoom) map.setZoom(defaultZoom);
    } else if (shouldResetCenter) {
      map.setCenter(defaultCenter);
      map.setZoom(defaultZoom);
      dispatch(setFilterState({ resetFilters: false }));
    }

    map.addListener('mousedown', () => {
      dispatch(setMapState({ selectedMapMarkerEstId: null }));
    });
  };

  useEffect(() => {
    dispatch(setMapState({
      bounds: {
        northEastLatitude: mapBounds.bounds[3],
        northEastLongitude: mapBounds.bounds[2],
        southWestLatitude: mapBounds.bounds[1],
        southWestLongitude: mapBounds.bounds[0],
      }
    }))
  }, [mapBounds]);

  useEffect(() => {
    if (googleApiObj) {
      const { map, maps } = googleApiObj;
      if (map) {
        if (locations && locations?.length > 0) onLoaded(map, maps, locations);
      }
    }
  }, [googleApiObj, locations, lockBounds])

  const handleMapChange = ({ bounds, center, zoom }: any) => {

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    setMapBounds({ ...mapBounds, bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()], zoom });
  };

  return (
    <Wrapper>
      <GoogleMap
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyCNVmTF4f5lgJSkhUDJY4q4PeDIl-d6mkY"}
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        options={{
          styles: mapStyles,
          disableDefaultUI: true,
          zoomControl: true,
          clickableIcons: false,
          gestureHandling: 'greedy',
          controlSize: isMobile ? 20 : 40,
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          setIsGoogleApiLoadedObj({ map, maps });
          mapRef.current = map;
        }}
        onChange={handleMapChange}
      >
        {clusters &&
          clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } = cluster.properties;

            if (isCluster) {
              let size = (pointCount * 20) / points.length;

              return (
                <ClusterMarker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  {pointCount}
                </ClusterMarker>
              );
            } else {
              return (
                <Marker
                  key={`marker-${cluster.properties.id}`}
                  id={cluster.properties.id}
                  lat={latitude}
                  lng={longitude}
                  estId={cluster.properties.estId}
                  baseWage={cluster.properties.baseWage}
                  avgTips={cluster.properties.avgTips}
                  sanitationScore={cluster.properties.sanitationScore}
                  type={mode}
                />
              )
            }
          })}
        <UserMarker lat={latitude} lng={longitude} />
      </GoogleMap>
    </Wrapper>
  );
};

export default KCGoogleMap;