
import { Box, Chip, Drawer, IconButton, Select, Stack, TextField, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';

import { ReactComponent as RedCheckmark } from '../../../../assets/svg/icons/red_checkmark.svg';
import KCSlider from '../../../../components/KCSlider';
import { Check } from '@mui/icons-material';
import { FormValues, NewReviewPageProps } from '../..';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

const StyledSelect = styled(Select)({
  backgroundColor: '#FFFFFF',
  borderRadius: '12px',
  maxWidth: '368px',
  height: '48px',
  border: '1px solid #837A78',
});

const StyledChipTextField = styled(TextField)({
  backgroundColor: '#FFFFFF',
  borderRadius: '12px',
  maxWidth: '200px',
  height: '32px',
  border: '1px solid #837A78',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    height: "32px",
    color: '#3E3938',
    backgroundColor: '#fff',
  },
  '&:hover': {
    borderColor: '#E44444',
  },
  

});

const availableBenefits = [
	"Health Insurance",
	"Paid Time Off",
	"Free Meals",
	"Family Meals",
	"Bonuses",
	"Professional Development",
	"Employee Discounts",
	"Retirement Plans",
	"Wellness Programs",
	"Transportation Reimbursement",
	"Training Opportunities",
	"Uniform Allowance",
	"Career Advancement",
  "+ Report another benefit",
]

export const ThirdPageValidationSchema = yup.object().shape({
  schedule_flexibility: yup.number().required('Schedule Flexibility is required'),
  employee_satisfaction: yup.number().required('Employee Satisfaction is required'),
  management_evaluation: yup.number().required('Management Approval is required'),
  benefits: yup.array().required('Benefits is required'),
});

const ThirdPage: React.FC<NewReviewPageProps> = (props) => {

  const formik = useFormikContext<FormValues>();

  const [selectedBenefits, setSelectedBenefits] = React.useState<string[]>(formik.values.benefits);

  const initialBenefits = availableBenefits.map((benefit) => ({
		label: benefit,
		value: benefit,
		selected: selectedBenefits.includes(benefit) ? true : false,
	}));

	const [selectedChips, setSelectedChips] = React.useState(initialBenefits);

  interface ChipData {
    label: string;
    value: string;
    selected: boolean;
  }

  useEffect(() => {
    formik.setFieldValue('benefits', selectedBenefits);
  }, [selectedBenefits]);

  const chipClick = (chipToSelect: ChipData) => () => {
    setSelectedChips((chips) =>
      chips.map((chip) => {
        if (chip.value === chipToSelect.value) {
          setSelectedBenefits((selectedBenefits) => {
            if (selectedBenefits.includes(chipToSelect.value)) {
              return selectedBenefits.filter((benefit) => benefit !== chipToSelect.value);
            } else {
              return [...selectedBenefits, chipToSelect.value];
            }
          });
          return {
            ...chip,
            selected: !chip.selected,
          };
        } else {
          return chip;
        }
      })
    );
  };

  const addCustomChip = () => {
    setIsAddingCustomChip(true);
  }

	const KCSelectChip = styled(Chip)(({ theme }) => ({
    backgroundColor: 'transparent',
    border: '1px solid #3E3938',
    borderRadius: '12px',
    color: '#3E3938',
		[`&:hover`]: {
			backgroundColor: '#FBF8F5',
			color: '#E44444',
      borderColor: '#E44444',
		},
	}));

  const KCAddChip = styled(Chip)(({ theme }) => ({
    backgroundColor: 'transparent',
    border: 'none',
    color: '#3E3938',
    textDecoration: 'underline',
    [`&:hover`]: {
      color: '#E44444',
      backgroundColor: 'transparent',
    },
  }));

  const [isAddingCustomChip, setIsAddingCustomChip] = React.useState(false);
  const [customChipText, setCustomChipText] = React.useState('');

  const handleCustomChipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomChipText(event.target.value);
  };

  const saveCustomChip = () => {
    if (customChipText) {
      setSelectedChips([...selectedChips, { label: customChipText, value: customChipText, selected: true }]);
      setSelectedBenefits([...selectedBenefits, customChipText]);
      setCustomChipText('');
    }
    setIsAddingCustomChip(false);
  };

  return (
   <Box sx={{ padding: "40px"}}>
      <Stack spacing={4}>
        <Box>
          <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '16px'}}>Benefits</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap'}}>
          {selectedChips.map((data) => (
              data.label === '+ Report another benefit' ? (
                isAddingCustomChip ? (
                  <StyledChipTextField
                    key="custom-chip-textfield"
                    value={customChipText}
                    onChange={handleCustomChipChange}
                    autoFocus
                    sx={{ margin: '0px 5px 5px 0px' }}
                    InputProps={{
                      endAdornment: (
                        <IconButton color="primary" onClick={saveCustomChip}>
                          <Check />
                        </IconButton>
                      ),
                      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          if(customChipText !== "") {
                            saveCustomChip()
                          }
                        }
                      }
                    }}
                  />
                ) : (
                  <KCAddChip
                    key={data.value}
                    label={data.label}
                    onClick={addCustomChip}
                    sx={{ margin: '0px 5px 5px 0px' }}
                  />
                )
              ) : (
                <KCSelectChip 
                  key={data.value} 
                  label={data.label} 
                  icon={data.selected ? <RedCheckmark /> : undefined}
                  sx={{ 
                    margin: '0px 5px 5px 0px', 
                    ...(data.selected && {
                      backgroundColor: '#FBF8F5',
                      color: '#E44444',
                      borderColor: '#E44444',
                      transition: 'background-color 0.5s ease',
                    }),
                  }} onClick={chipClick(data)} />
              )
            ))}
          </Box>
        </Box>
        <Box>
          <Typography variant="body1"><span style={{ fontWeight: 'bold'}}>Schedule flexibility:</span> How easily can you change your work hours or shifts to fit your life?</Typography>
          <KCSlider 
            rainbow={false}  
            min={1}
            max={5}
            value={formik.values.schedule_flexibility}
            onChange={(event, value) => formik.setFieldValue('schedule_flexibility', value)}
            marks={[
            {
              value: 1,
              label: 'Not flexible',
            },
            {
              value: 5,
              label: 'Very Flexible',
            },]}
          />
        </Box>
        <Box>
          <Typography variant="body1"><span style={{ fontWeight: 'bold'}}>Employee satisfaction:</span> How likely are you to recommend this establishment for work?</Typography>
          <KCSlider 
            rainbow={false} 
            min={1}
            max={5}
            value={formik.values.employee_satisfaction}
            onChange={(event, value) => formik.setFieldValue('employee_satisfaction', value)}
            marks={[
            {
              value: 1,
              label: 'Don’t recommend establishment',
            },
            {
              value: 5,
              label: 'Highly recommend establishment',
            },]}/>
        </Box>
        <Box>
          <Typography variant="body1"><span style={{ fontWeight: 'bold'}}>Approval of management:</span> Do you approve of management style and culture?</Typography>
          <KCSlider 
            rainbow={false} 
            min={1}
            max={5}
            value={formik.values.management_evaluation}
            onChange={(event, value) => formik.setFieldValue('management_evaluation', value)}
            marks={[
            {
              value: 1,
              label: 'Don’t approve',
            },
            {
              value: 5,
              label: 'Highly approve of',
            },]}/>
        </Box>
      </Stack>
   </Box>
  );
};

export default ThirdPage;
