import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MapState {
  selectedMapMarkerEstId?: any;
  animateMarkerId?: any;
  bounds?: any;
  lockBounds?: any;
  isListVisible?: boolean;
  isSelectedCardVisible?: boolean;
}

const initialState: MapState = {
  selectedMapMarkerEstId: null,
  bounds: null,
  lockBounds: null,
  animateMarkerId: null,
  isListVisible: true,
  isSelectedCardVisible: false,
};

const slice = createSlice({
  name: 'mapState',
  initialState,
  reducers: {
    setMapState: (state, action: PayloadAction<Partial<MapState>>) => {
      const { selectedMapMarkerEstId, animateMarkerId, bounds, lockBounds, isListVisible, isSelectedCardVisible } = action.payload;

      state.bounds = bounds !== undefined ? bounds : state.bounds;
      state.lockBounds = lockBounds !== undefined ? lockBounds : state.lockBounds;
      state.animateMarkerId = animateMarkerId !== undefined ? animateMarkerId : state.animateMarkerId;
      state.selectedMapMarkerEstId = selectedMapMarkerEstId !== undefined ? selectedMapMarkerEstId : state.selectedMapMarkerEstId;
      state.isListVisible = isListVisible !== undefined ? isListVisible : state.isListVisible;
      state.isSelectedCardVisible = isSelectedCardVisible !== undefined ? isSelectedCardVisible : state.isSelectedCardVisible;
    },
    initMapState: (state) => {
      return { ...initialState };
    },
  },
});

export const { 
  setMapState,
  initMapState,
} = slice.actions;

export default slice.reducer;

export const selectCurrentMapState = (state: { mapState: MapState }) => state.mapState;
