import { Box, Button, Container, Grid, Link, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { ReactComponent as WisperLogo } from '../../assets/svg/HomePage/wisper_logo.svg';
import { ReactComponent as BlackFried } from '../../assets/svg/kc-fries-black.svg';
import { ReactComponent as RedDonut } from '../../assets/svg/kc-donut-red.svg';
import { ReactComponent as StudioTao } from '../../assets/svg/Partners/studio-tao.svg';
import { ReactComponent as RocLogo } from '../../assets/svg/Partners/roc.svg';
import { ReactComponent as ChowLogo } from '../../assets/svg/Partners/chow.svg';
import FakeReviewCard from './components/FakeReviewCard';
import KCButtonBase from '../../components/Button';
import KCSearchField from '../../components/KCSearchField';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';

const HomePage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loginWithRedirect } = useAuth0();
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  let navigate = useNavigate();
  function writeAReview() {
    ReactGA.event({
      category: "User Interaction",
      action: "Click",
      label: "Write Review"
    })
    navigate("/write-review");
  }

  function handleSearch(search: string) {
    ReactGA.event({
      category: "User Search Interaction",
      action: "Click",
      label: search
    })
    navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/explore?search=${search}`)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }

  return (
    <Box>
      <Box sx={{
        backgroundColor: '#FBF8F5',
        padding: '58px 0px',
        [theme.breakpoints.down('md')]: {
          padding: '10px 0px',
        },
      }}>
        <Container>
          <Grid container sx={{ padding: '10px 0px' }}>
            <Grid item xs={12} md={7} order={{ xs: 2, sm: 2, md: 1 }}>
              <Stack spacing={4}>
                <Typography variant="h2" color="text.primary" sx={{
                  [theme.breakpoints.down('md')]: {
                    textAlign: 'center',
                  },
                }}>
                  Shape the <i>future</i> of New York City’s hospitality industry.
                </Typography>
                <Typography variant="body1" color="text.secondary"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      textAlign: 'center',
                    },
                  }}
                >
                  Your experiences have the power to help others. Write a review today and help shape the future of the hospitality industry.
                </Typography>
                <Box sx={{
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                    justifyContent: 'center'
                  },
                }}>
                  <KCButtonBase
                    variant="contained"
                    sx={{
                      width: '326px',
                      [theme.breakpoints.down('md')]: {
                        width: '100%',
                      },
                    }}
                    onClick={writeAReview}
                  >
                    Write an anonymous review
                  </KCButtonBase>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5} order={{ xs: 1, sm: 1, md: 2 }} sx={{ display: 'flex', justifyContent: 'center' }}>
              <WisperLogo style={{ width: isMobile ? '216px' : '437px', height: isMobile ? '227px' : '548px', aspectRatio: '1/1' }} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{
        backgroundColor: '#3E3938',
        padding: '30px 0px',
        width: '100%',
        minHeight: '650px',
      }}>
        <Container>
          <Box sx={{ position: 'relative', margin: '20px 0px' }} >
            <Box sx={{ position: 'relative' }}>
              <FakeReviewCard
                stars={1.5}
                reviewText="Despite its Michelin star, the kitchen cuts corners, even sourcing ingredients from surprising places. The work environment is harsh, with extremely long hours and little regard for staff welfare. I wouldn't recommend working here based on my experience."
                baseRate={20}
                avgTips={10}
                sanitationScore="A"
              />
            </Box>
            <Box sx={{
              position: 'absolute',
              backgroundColor: 'secondary.main',
              right: 0,
              bottom: -160,
              zIndex: 2,
              [theme.breakpoints.down('lg')]: {
                position: 'relative',
                right: 0,
                bottom: 0,
                marginTop: '20px',
              },
            }}>
              <FakeReviewCard
                stars={5}
                reviewText="I love this place! The food is amazing, and the staff is so friendly. I always feel welcome here."
                baseRate={20}
                avgTips={10}
                sanitationScore="C"
              />
            </Box>
          </Box>
          <Box sx={{
            [theme.breakpoints.up('md')]: {
              marginTop: '200px',
            },
            display: 'flex',
            justifyContent: 'center'
          }}>
            <KCButtonBase
              variant="contained"
              sx={{
                width: '270px',
                backgroundColor: '#E44444',
                color: '#FBF8F5',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
              onClick={() => {
                ReactGA.event({
                  category: "User Interaction",
                  action: "Click",
                  label: "Read More Reviews"
                })
                navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/explore`);
              }}
            >
              Read more reviews
            </KCButtonBase>
          </Box>
        </Container>
      </Box>
      <Box sx={{
        backgroundColor: '#F1E8DE',
        padding: '58px 0px',
        [theme.breakpoints.down('md')]: {
          padding: '10px 0px',
        },
      }}>
        <Container>
          <Grid container sx={{ padding: '20px 0px' }} spacing={8}>
            <Grid item xs={12} md={7} order={{ xs: 2, sm: 2, md: 1 }}>
              <Stack spacing={4}>
                <Typography
                  variant="h2"
                  color="text.secondary"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      textAlign: 'center',
                    },
                  }}
                >Explore local establishments</Typography>
                <Typography variant="body1" color="text.secondary"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      textAlign: 'center',
                    },
                  }}
                >
                  Find the establishments in your area that are treating their employees the best.
                </Typography>
                <KCSearchField buttontext='Search' outlined={false} placeholder='Start typing...' buttonHandleClick={() => handleSearch(searchTerm)}
                value={searchTerm}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                inputProps={{
                  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      handleSearch(e.currentTarget.value)
                    }
                  }
                }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={5} display="flex" justifyContent="center" alignItems="center" order={{ xs: 1, sm: 1, md: 2 }}>
              <BlackFried style={{ width: isMobile ? '288px' : '474px', height: isMobile ? '288px' : '474px' }} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{
        backgroundColor: '#E44444',
        [theme.breakpoints.down('md')]: {
          backgroundColor: '#FBF8F5',
        },
      }}>
        <Grid container>
          <Grid item xs={12} md={5} display="flex" justifyContent="center" alignItems="center" sx={{
            backgroundColor: '#FBF8F5'
          }}>
            <Box sx={{ padding: '20px', display: 'flex', justifyContent: "center" }}>
              <RedDonut style={{ width: isMobile ? '330px' : '549px', height: isMobile ? '290px' : '485px' }} />
            </Box>

          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing={4} sx={{
              padding: '87px 124px',
              [theme.breakpoints.down('md')]: {
                padding: '10px 20px',
              },
            }}>
              <Typography
                variant="h2"
                color="common.white"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    color: '#E44444',
                    textAlign: 'center',
                  },
                }}
              >
                Join the community
              </Typography>
              <Typography
                variant="body1"
                color="common.white"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    color: 'text.secondary',
                    textAlign: 'center',
                  },
                }}
              >
                Our platform is built by and for food and drink professionals like you. Join our community and contribute to a collective voice that advocates for better working environments and fair treatment.
              </Typography>
              <Box sx={{
                paddingBottom: '40px',
                [theme.breakpoints.down('md')]: {
                  display: 'flex',
                  justifyContent: 'center'
                },
              }}>
                <KCButtonBase
                  variant="contained"
                  sx={{
                    width: '270px',
                    backgroundColor: '#FBF8F5',
                    color: '#E44444',
                    [theme.breakpoints.down('md')]: {
                      backgroundColor: '#E44444',
                      color: '#FBF8F5',
                      width: '100%',
                    },
                  }}
                  onClick={() => loginWithRedirect({
                    appState: {
                      returnTo: window.location.href
                    },
                    authorizationParams: {
                      screen_hint: "signup"
                    }
                  })}
                >
                  Create an account
                </KCButtonBase>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{
        backgroundColor: '#FFFFFF',
        padding: '95px',
        [theme.breakpoints.down('md')]: {
          padding: '48px'
        }
      }}>
        <Container>
          <Typography
            variant="h6"
            color="text.primary"
            textAlign="center"
            sx={{
              textTransform: 'uppercase', fontFamily: 'IBM Plex Sans',
              "&.MuiTypography-root": {
                fontSize: '24px',
                letterSpacing: 'unset'
              }
            }}>

            Our Partners</Typography>
          <Grid container sx={{ marginTop: '20px' }} spacing={6}>
            <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
              <Link href="https://www.studioatao.org/" target="_blank" rel="noreferrer">
                <StudioTao />
              </Link>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
              <Link href="https://chowco.org/" target="_blank" rel="noreferrer">
                <ChowLogo />
              </Link>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
              <Link href="https://rocunited.org/newyork/" target="_blank" rel="noreferrer">
                <RocLogo />
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default HomePage;
