
import { OutlinedTextFieldProps, TextField, TextFieldProps, styled } from '@mui/material';
import React from 'react';

interface KCTextFieldProps extends OutlinedTextFieldProps {
  customProp?: string;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: '#3E3938',
    borderRadius: '12px',
    backgroundColor: '#fff',
    // '& fieldset': {
    //   borderColor: '#E4E0DF',
    //   border: '1px solid',
    // },
    '&:hover fieldset': {
      borderColor: '#837A78',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const KCTextField: React.FC<KCTextFieldProps> = (props) => {
  return <StyledTextField {...props}  sx={{
    ...(props.multiline ? {} : 
    { 
      '& .MuiOutlinedInput-root': {
        height: '48px' 
      }
    }),
  }}
/>;
};
export default KCTextField;
