import React from 'react';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import { Toolbar, Typography, styled, IconButton, Box, Button, Tooltip, Avatar, Menu, MenuItem, Container, useScrollTrigger, useTheme, useMediaQuery, Stack, Skeleton } from '@mui/material';
import MobileDrawer from './components/MobileDrawer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as MenuIcon } from '../../assets/svg/icons/menu_icon.svg';
import KCLogo from '../../assets/svg/Logo/logo.svg';
import KCDialog from '../KCDialog';
import KCButtonBase from '../Button';
import ReactGA from 'react-ga4';

const CustomAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
  boxShadow: "none",
}));

interface ElevationScrollProps {
  children: React.ReactElement;
}


function ElevationScroll(props: ElevationScrollProps) {

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(props.children, {
    elevation: trigger ? 4 : 0,
  });
}


// interface for props that include pages and their routes
export interface NavBarProps {
  pages: Page[];
}

// interface for a page
export interface Page {
  title: string;
  route: string;
}


const NavBar: React.FC<NavBarProps> = ({ pages }) => {

  const location = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { loginWithRedirect, logout, isAuthenticated, user, isLoading } = useAuth0();

  const logoutWithDialog = () => {
    setDialogOpen(true);
  }

  const backgroundColor = () => {
    if (location.pathname === '/about') {
      return '#F1E8DE';
    }

    if (location.pathname.includes('/new-review')) {
      if (isMobile) {
        return '#FFF';
      }
      return '#FBF8F5';
    }

    return '#FBF8F5';
  }

  const handleDrawerToggle = () => {
    ReactGA.event({
      category: "User Interaction",
      action: "Click",
      label: "Mobile Drawer Menu"
    })
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const drawerWidth = '100%';

  return (
    <>
      <CustomAppBar position="fixed" sx={{ backgroundColor: backgroundColor}}>
        <Container>
        <Toolbar sx={{ padding: '0px !important'}}>
          <Link to="/">
            <Box
              component="img"
              sx={{
                height: '34px',
                width: '35px',
                marginRight: '16px',
              }}
              alt="Kitchen Confidentials Logo"
              src={KCLogo}
            />
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, gap: 4 }}>
            {pages.map((page) => (
              <Button 
                key={page.route} 
                component={Link}
                to={page.route}
                disableRipple
                sx={{
                  color: 'text.secondary',
                  textTransform: 'none',
                  borderRadius: '6px',
                  textDecoration: location.pathname === page.route ? 'underline !important' : 'none',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    fontStyle: 'italic',
                  },
                  '&:focus': {
                    border: location.pathname !== page.route ? '1px solid' : 'none'
                  }
                }}
              >
                <Typography variant="body1" textAlign="center">
                  {page.title}
                </Typography>
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <Button 
              key="login" 
              onClick={() => {
                if (isAuthenticated) {
                  navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/profile`)
                  return;
                }
                loginWithRedirect({
                  appState: {
                    returnTo: window.location.href,
                  },
                });
              }}
              sx={{
                color: 'text.secondary',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                  fontStyle: 'italic',
                },
                '&:focus': {
                  textDecoration: 'underline'
                }
              }}
            >
              <Typography variant="body1" textAlign="center">
                {isLoading ? <Skeleton width={110} sx={{ fontSize: '1rem' }} /> : (isAuthenticated ? "Hi, " + user?.nickname : "Login") }
              </Typography>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <Button 
              key="write-review" 
              onClick={() => {
                ReactGA.event({
                  category: "User Interaction",
                  action: "Click",
                  label: "Write Review"
                })
                navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/write-review`)
              }}
              sx={{
                color: 'text.secondary',
                textTransform: 'none',
                backgroundColor: '#F1E8DE',
                borderRadius: '12px',
                padding: '8px 30px',
                marginLeft: '16px',
              }}
            >
              <Typography variant="body1" textAlign="center" sx={{
                fontWeight: 'bold',
              }}>
               Write a Review
              </Typography>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, display: { md: 'none' }  }}>
          </Box>
          <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ flexGrow: 0, mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon style={{ color: "text.secondary" }}/>
          </IconButton>
        </Toolbar>
        </Container>
      </CustomAppBar>
      <Toolbar />
      <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      >
        {isLoading ? null : (
        <MobileDrawer
          variant="temporary"
          open={mobileOpen}
          isauthenticated={isAuthenticated}
          user={user}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          pages={pages}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { backgroundColor: "primary.main", boxSizing: 'border-box', width: drawerWidth },
          }}
        />
        )}
      </Box>
    </>

  );
};

export default NavBar;
