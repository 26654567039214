import React, { useEffect } from 'react';
import './App.css';
import {  
  createBrowserRouter, 
  Navigate,
  RouterProvider } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Layout from './pages/Layout';
import ExplorePage from './pages/ExplorePage';
import ProfilePage from './pages/ProfilePage';
import AboutUsPage from './pages/AboutUsPage';
import ContactUsPage from './pages/ContactUsPage';
import EstablishmentPage from './pages/EstablishmentPage';
import NewReviewPage from './pages/NewReviewPage';
import WriteReviewPage from './pages/WriteReviewPage';
import NewReviewLandingPage from './pages/NewReviewPage/landingPage';
import NewReviewSubmittedPage from './pages/NewReviewPage/submittedPage';
import AuthRedirectPage from './pages/RedirectPages/AuthRedirectPage';
import { useAuth0 } from "@auth0/auth0-react";
import { setCredentials } from "./store/authSlice";
import { useDispatch } from 'react-redux';
import NotFound from './pages/ErrorPage/NotFound';
import NewEstablishmentRedirect from './pages/RedirectPages/NewEstablishmentRedirect';
import PrivacyPolicyPage from './pages/PolicyPages/PrivacyPolicyPage';
import TermsOfUsePage from './pages/PolicyPages/TermsOfUsePage';
import ReactGA from 'react-ga4';
import { useGetUserWithIdQuery , GetUserWithIdApiArg} from './store/kcApi';

export default function App() {

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID as string);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  const { isLoading, isAuthenticated, user, getAccessTokenSilently, logout} = useAuth0();
  const dispatch = useDispatch();

  const [userSearch, setUserSearch] = React.useState<string>("");

  const {
    data: kcUser,
    error,
    isLoading: userLoading,
  } = useGetUserWithIdQuery(
    { userId: userSearch } as GetUserWithIdApiArg,
    
  );

  useEffect(() => {
    const initAuth0 = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        dispatch(setCredentials({token: accessToken, user: user?.sub, appstate: null, user_id: kcUser?.id || null}));
      }
    };
    initAuth0();

    if (isAuthenticated && !isLoading && user !== undefined) {
      setUserSearch(user?.sub || "");
    }
  }, [isAuthenticated, isLoading, user, kcUser, userLoading]);

  const RedirectToAuth0 = () => {
    useEffect(() => {
      window.location.href = process.env.REACT_APP_LOGIN_URL + "?response_type=code&client_id=" + process.env.REACT_APP_AUTH0_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_AUTH0_CALLBACK_URL;
    }, []);
  
    return null;
  }

  const LogoutComponent = () => {
    useEffect(() => {
      logout({ logoutParams: { returnTo: "/" }});
    }, []);
  
    return null;
  }

  const router = createBrowserRouter([
    { Component: Layout, 
      children: [
        { path: "/", Component: HomePage },
        { path: "/login", Component: RedirectToAuth0 },
        { path: "/logout", Component: LogoutComponent },
        { path: "/redirect", Component: AuthRedirectPage},
        { path: "/home", Component: HomePage },
        { path: "/explore", Component: ExplorePage },
        { path: "/profile", Component: ProfilePage },
        { path: "/establishments", Component: ExplorePage },
        { path: "/about", Component: AboutUsPage },
        { path: "/contact", Component: ContactUsPage },
        { path: "/write-review", Component: WriteReviewPage },
        { path: "/establishments/new", Component: NewEstablishmentRedirect},
        { path: "/establishments/:establishmentId/new-review",
          Component: NewReviewLandingPage,
        },
        { path: "/establishments/:establishmentId/new-review/:reviewId",
          Component: NewReviewPage,
        },
        { path: "/establishments/:establishmentId/new-review/:reviewId/submitted",
          Component: NewReviewSubmittedPage,
        },
        { path: "/establishments/:establishmentId", 
          Component: EstablishmentPage,
        },
        { path: "/privacy", Component: PrivacyPolicyPage },
        { path: "/terms", Component: TermsOfUsePage},
        { path: "/welcome", element: <Navigate to="/" replace />},
        {
          path: "*",
          Component: NotFound
        }
    ]}
  ]);


  return <RouterProvider router={router} />;
}

