import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

const KCSearchFieldStyled = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    height: "48px",
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const KCSearchFieldStyledOutlined = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    height: "48px",
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    borderColor: theme.palette.secondary.main,
    border: '1px solid',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const KCSearchFieldButtonStyled = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  borderRadius: '0px 8px 8px 0px',
  marginRight: '-16px',
  height: '48px',
  width: '223px',
  border: 'none',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'none',
  },
}));

type KCSearchFieldProps = TextFieldProps & {
  buttontext: string;
  outlined: boolean;
  buttonHandleClick: (input: string) => void;
};


const KCSearchField: React.FC<KCSearchFieldProps> = ({buttontext, outlined, buttonHandleClick, ...props}) => {

  const [searchInput, setSearchInput] = React.useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  }

  return (
    !outlined ? (
    <KCSearchFieldStyled
      {...props}
      variant="outlined"
      value={props.value || searchInput}
      onChange={props.onChange || handleChange}
      InputProps={{
        endAdornment: (
          <KCSearchFieldButtonStyled variant="contained" color="primary" onClick={() => buttonHandleClick(searchInput)}>
            {buttontext}
          </KCSearchFieldButtonStyled>
        ),
      }}
    />) : (
    <KCSearchFieldStyledOutlined
      {...props}
      variant="outlined"
      value={props.value || searchInput}
      onChange={props.onChange || handleChange}
      inputProps={{
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            buttonHandleClick(e.currentTarget.value)
          }
        }
      }}
      InputProps={{
        endAdornment: (
          <KCSearchFieldButtonStyled variant="contained" color="primary" onClick={() =>  buttonHandleClick(searchInput)}>
            {buttontext}
          </KCSearchFieldButtonStyled>
        ),
      }}
    />)
  );
};

export default KCSearchField;
