import {
  Box,
  Chip,
  IconButton,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { ReactComponent as BaseWage } from "../../../../assets/svg/icons/base_wage.svg";
import { ReactComponent as AvgTips } from "../../../../assets/svg/icons/avg_tips.svg";
import { ReactComponent as Sanitation } from "../../../../assets/svg/icons/sanitation.svg";
import { useMemo, useState } from "react";
import KCGoogleMap from "./KCGoogleMap";
import { IPosition } from "./Marker";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks/hooks";
import { selectCurrentMapState, setMapState } from "../../../../store/mapSlice";

export interface MapViewProps {
  markersData: IPosition[];
}

const StyledChip = styled(Chip)(({ theme }) => ({
  padding: "8px",
  borderRadius: "100px",
  fontSize: "16px",
  color: theme.palette.text.secondary,
  textAlign: "center",
  boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.25);",
  "&:hover": {
    backgroundColor: "#FBFBFA",
  },
  "& .MuiChip-icon": {
    margin: "0px",
  },
  "& .MuiChip-label": {
    paddingLeft: "8px",
    paddingRight: "4px",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

const getViewModeIcons = (mode: string, width: number) => {
  if (mode == "Base Wage") return <BaseWage width={width} height={width} />;
  if (mode == "Avg.tips") return <AvgTips width={width} height={width} />;
  if (mode == "Sanitation") return <Sanitation width={width} height={width} />;
};

const MapView: React.FC<MapViewProps> = ({ markersData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const mapState = useAppSelector(selectCurrentMapState);
  const isListVisible = mapState?.isListVisible;

  const viewModes = ["Base Wage", "Avg.tips", "Sanitation"];

  const [viewMode, setViewMode] = useState<string>("Base Wage");

  const renderGoogleMap = useMemo(() => {
    return <KCGoogleMap
      mode={viewMode}
      locations={markersData}
    />
  }, [markersData, viewMode]);

  return (
    <Box position="relative">
      <Box width="100%" height={{ md: "750px", xs: "400px" }}>
        {renderGoogleMap}
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        p={2}
        zIndex={2}
        top={0}
        position="absolute"
      >
        {viewModes.map((mode, index) => (
          <StyledChip
            key={index}
            icon={getViewModeIcons(mode, isMobile ? 18 : 24)}
            label={mode}
            sx={{
              border: viewMode === mode ? "1px solid" : "none",
              backgroundColor: "white",
            }}
            onClick={() => setViewMode(mode)}
          />
        ))}
      </Stack>
      <Box
        position='absolute'
        bottom='20px'
        left='60px'
        right='60px'
        padding='6px'
        display="flex"
        justifyContent='center'
      >
        <Box
          bgcolor="common.white"
          color="common.black"
          padding='4px 12px'
          borderRadius='8px'
          sx={{
            cursor: 'pointer',
            boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.25)"
          }}
          display="flex"
          gap='8px'
          onClick={() => {
            dispatch(setMapState({ isListVisible: !isListVisible }));
          }}
        >
          <Typography>{isListVisible ? `Map View` : `List View`}</Typography>
          {isListVisible ? <MapOutlinedIcon /> : <FormatListBulletedOutlinedIcon />}
        </Box>
      </Box>
    </Box>
  );
};

export default MapView;