
import React, { useState } from 'react';
import { Box, Divider, Pagination, Skeleton, Stack, Typography } from '@mui/material';
import { ReactComponent as PizzaGuy } from '../../../assets/svg/pizza_guy.svg';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Review, useGetUserReviewsQuery } from '../../../store/kcApi';
import KCButtonBase from '../../../components/Button';
import ReviewCard from './ReviewCard';
import { useSelector } from "react-redux";
import { RootState } from '../../../store/store';

const Reviews: React.FC = () => {
  
  const numberOfReviews = 3;
  const [page, setPage] = useState(1);
  const totalReviews = 20;
  const navigate = useNavigate();

  const currentUser = useSelector((state: RootState) => state.auth);

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => { 
    setPage(page); 
  }; 

  const { 
    data: rawReviews, 
    error: reviewsError, 
    isLoading: loadingReviews
  } = useGetUserReviewsQuery({ userId: currentUser.user_id });

  if (loadingReviews) {
    return (  
      <Stack spacing={4}>
        <Skeleton variant="rounded" width='40vw' height={210} />
        <Skeleton variant="rounded" width='40vw' height={210} />
        <Skeleton variant="rounded" width='40vw' height={210} />
      </Stack>
    );
  } else if (reviewsError !== undefined) {
    return (
      <Box>
        <Typography variant='h5' sx={{ color: 'red' }}>
          Error
        </Typography>
      </Box>
    );
  }

  if (!rawReviews?.data || rawReviews?.data.length === 0) {
    return (
      <Box>
        <Box
          sx={{
           backgroundColor: '#FFFFFF',
           padding: '40px', 
          }}
        >
          <Stack spacing={3} justifyContent="center" alignItems="center">
            <Typography variant='body1' color='text.secondary'>
              No reviews yet.
            </Typography>
            <Box>
              <PizzaGuy />
            </Box>
          </Stack>
        </Box>  
      </Box>
    );
  }

  return (
    <Box>
      <Stack divider={<Divider/>} alignItems="space-between">
        {rawReviews?.data?.map((review: Review) => (
          <ReviewCard 
            key={review.id} 
            review={review.review || ''}
            rating={review.overall_rating || 0}
            baseRate={review.wage || 0}
            avgTips={review.avg_tip_take || 0}
            createdDate={review.createdAt || ''}
            id={review.id || ''}
            status={review.status || 'draft'}
            establishmentId={review.establishment_id || ''}
          />
        ))}
        {/* {rawReviews?.next_page != undefined && rawReviews?.next_page > 0 && rawReviews?.total_items != undefined && rawReviews?.total_items > 0 && (
          <Pagination count={Math.ceil(rawReviews?.total_items/numberOfReviews)} page={page} size="small" onChange={handleChange} />
        )} */}
      </Stack>
    </Box>

  );
};

export default Reviews;