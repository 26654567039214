
import React from 'react';
import { styled, Theme, Slider, SliderProps, SliderThumb, Stack, Box, Typography } from '@mui/material';
// import { ReactComponent as SilderIcon } from '../../assets/svg/icons/slider_arrow.svg';
import { SliderComponentsPropsOverrides } from '@mui/material';


const KCSliderTheme = styled(Slider)(({ theme }) => ({
  height: 8,
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    '&:before': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-markLabel': {
    paddingLeft: 32,
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: 'transparent',
    color: '#fff',
    '& *': {
      backgroundColor: 'transparent',
      color: '#fff',
    },
  },
  '& .MuiSlider-rail': {
    border: 'none',
    // background: 'linear-gradient(90deg, #E44444 0%, #FFE200 48.96%, #00992B 100%)',
    opacity: 1,
  },
}));

interface SliderIconProps {
  color?: string;
}

const SilderIcon: React.FC<SliderIconProps> = ({ color }) => (
  <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5848 18.3571C12.6143 20.044 10.1894 20.044 9.21894 18.3571L1.10578 4.25446C0.133865 2.56502 1.35006 0.4571 3.2887 0.4571L19.515 0.457102C21.4536 0.457102 22.6698 2.56503 21.6979 4.25446L13.5848 18.3571Z" fill={color} stroke="#3E3938" strokeWidth="0.723214"/>
  </svg>
);

function KCThumbComponent(props: any) {
  const { children, style, ...other } = props;

  let iconColor;
  const value = children.props.value || 0;
  // Determine the icon color based on the slider value
  switch(value) {
    case 1:
      iconColor = '#E44444';
      break;
    case 2:
      iconColor = '#EE7B2D';
      break;
    case 3:
      iconColor = '#FBE101';
      break;
    case 4:
      iconColor = '#99C512';
      break;
    case 5:
      iconColor = '#0D9D29';
      break;
    default:
      iconColor = '#E44444';
  };

  return (
    <SliderThumb {...other} style={{ ...style}}>
      <SilderIcon color={iconColor} />
      {children}
    </SliderThumb>
  );
}

interface ExtendedSliderProps extends SliderProps {
  // Add your additional properties here
  rainbow: boolean;
  marks: Array<{value: number, label: string}>;
}

const KCSlider: React.FC<ExtendedSliderProps> = ({ rainbow, marks, ...props}) => {

  return (
    <Stack direction="column" spacing={2}>
        <KCSliderTheme 
          slots={{ thumb: KCThumbComponent }}
          aria-label="Custom thumb label"
          track={false} 
          {...props} 
          marks={false}
          sx={{
            '& .MuiSlider-rail': {
              background: rainbow ? 'linear-gradient(90deg, #E44444 0%, #FFE200 48.96%, #00992B 100%)' : 'transparent',
              border: rainbow ? 'none' : '1px solid #837A78',
            }
          }}
      />
      <Stack direction="row" justifyContent="space-between" sx={{ 
        marginTop: '4px !important',
      }}>
        {marks.map((mark) => (
          <Typography variant="body2" color="text.secondary">{mark.label}</Typography>
        ))}
      </Stack>

    </Stack>
    
  );
};

export default KCSlider;
