import { Alert, Backdrop, Box, Button, CircularProgress, Container, Drawer, Stack, Step, StepLabel, Stepper, Tab, Tabs, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FirstPage, { FirstPageValidationSchema } from './components/FirstPage';
import SecondPage, { SecondPageValidationSchema } from './components/SecondPage';
import ThirdPage, { ThirdPageValidationSchema } from './components/ThirdPage';
import { useNavigate, useParams,useSearchParams } from 'react-router-dom';
import { ResponsiveContainer } from './landingPage';
import { useGetPublicReviewWithIdQuery,GetPublicReviewWithIdApiArg, useGetPublicEstablishmentWithIdQuery, useUpdatePublicReviewMutation, UpdatePublicReviewApiArg } from '../../store/kcApi';
import { Form, Formik, useFormik, FormikHelpers } from 'formik';
import KCButtonBase from '../../components/Button';
import { ReactComponent as GreenStar } from '../../assets/svg/icons/green_star.svg';
import { LocalReview } from '../../utils/helpers';
import ExitDialog from './components/ExitDialog';
import ReactGA from 'react-ga4';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

const steps = ['Rating & Review', 'Role & Compensation', 'Culture & Management'];

const StyleTabs = styled(Tabs)({
  backgroundColor: '#FFFFFF',
  paddingRight: '40px',
  paddingLeft: '40px',
});

const StyleTab = styled(Tab)({
  backgroundColor: '#FFFFFF',
  textTransform: 'none',
  borderBottom: '0.52px solid #3E3938',
  '&.Mui-selected': {
    color: '#3E3938',
  },

});

export interface NewReviewPageProps {
  onSubmit: (values: any, bag: FormikHelpers<any>) => void;
  validationSchema: any;
  formik?: any;
}

export interface FormValues {
  overall_rating: number;
  last_year_of_employment: number;
  role: string;
  tip_pool: boolean;
  base_wage: number | string;
  wage_type: string;
  avg_tips_per_shift: number | string;
  avg_length_of_shift: number | string;
  avg_shifts_per_week: number | string;
  avg_busyness: number;
  schedule_flexibility: number;
  employee_satisfaction: number;
  management_evaluation: number;
  benefits: string[];
  review: string;
}


const NewReviewPage: React.FC = () => {
  const theme = useTheme();

  const initialValues: FormValues = {
    overall_rating: 0,
    last_year_of_employment: new Date().getFullYear(),
    review: '',
    role: '',
    tip_pool: false,
    base_wage: '',
    wage_type: 'hourly',
    avg_tips_per_shift: '',
    avg_length_of_shift: '',
    avg_shifts_per_week: '',
    avg_busyness: 1,
    schedule_flexibility: 3,
    employee_satisfaction: 3,
    management_evaluation: 3,
    benefits: [],
  };

  const [updateNewReview] = useUpdatePublicReviewMutation();
  const [exitDialogOpen, setExitDialogOpen] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const { reviewId } = useParams<{ reviewId: string }>();
  let navigate = useNavigate();
  const [querySearch, setQuerySearch] = useSearchParams();
  
  const stepPages = [
    <FirstPage 
      onSubmit={() => {
        ReactGA.event({
          category: "New Review Form",
          action: "Wizard Page Change",
          label: "Step 1",
        });
      }}
      validationSchema={FirstPageValidationSchema}
      />,
    <SecondPage 
      onSubmit={() => {
        ReactGA.event({
          category: "New Review Form",
          action: "Wizard Page Change",
          label: "Step 2",
        });
      }}
      validationSchema={SecondPageValidationSchema}
    />,
    <ThirdPage 
    onSubmit={() => {
      ReactGA.event({
        category: "New Review Form",
        action: "Wizard Page Change",
        label: "Step 3",
      });
    }}
    validationSchema={ThirdPageValidationSchema}
    />
  ];
  const [activeStep, setActiveStep] = useState(0);
  const step = stepPages[activeStep] as React.ReactElement<any>;
  const totalSteps = stepPages.length;
  const isLastStep = activeStep === totalSteps - 1;
  const [isDuplicateAlert, setIsDuplicateAlert] = useState(false);

  const getStep = (formik: any) => {
    return React.cloneElement(step, {
      formik: formik,
    });
  }

  const [snapshot, setSnapshot] = useState(initialValues);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const {
    data: review,
    isLoading: loadingReview,
    error: errorReview,
    isError: isErrorReview,
  } = useGetPublicReviewWithIdQuery({ reviewId: reviewId || '' });

  const {
    data: establishment,
    isLoading: loadingEstablishment,
    error: errorEstablishment,
    isError: isErrorEstablishment,
  } = useGetPublicEstablishmentWithIdQuery({ establishmentId: establishmentId || '' });

  const handleNext = (values: any) => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (values: any) => {
    window.scrollTo(0, 0);
    if (activeStep === 0) {
      ReactGA.event({
        category: "User Interaction",
        action: "Click",
        label: "Exit New Review",
      });
      setExitDialogOpen(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onSubmit = (values: any, bag: FormikHelpers<any>) => {
    const patchReview: UpdatePublicReviewApiArg = {
      reviewId: reviewId || "",
      createUpdateReview: {
        establishment_id: establishmentId,
        status: "submitted",
        review_page: activeStep + 1,
        overall_rating: values.overall_rating,
        last_year_employed: values.last_year_of_employment,
        review: values.review,
        role: values.role,
        tip_pool_house: values.tip_pool,
        wage: parseFloat(values.base_wage),
        wage_type: values.wage_type,
        avg_tip_take: parseFloat(values.avg_tips_per_shift),
        avg_length_of_shift: typeof values.avg_length_of_shift === 'number' ? values.avg_length_of_shift : undefined,
        avg_shifts_per_week: typeof values.avg_shifts_per_week === 'number' ? values.avg_shifts_per_week : undefined,
        avg_busyness: values.avg_busyness,
        schedule_flexibility: values.schedule_flexibility,
        employee_satisfaction: values.employee_satisfaction,
        management_evaluation: values.management_evaluation,
        benefits: values.benefits,
      }
    };

    updateNewReview(patchReview)
    .unwrap()
    .then((payload) => {
      // update the local review status to "submitted"
      const reviewsJsonString = localStorage.getItem('kc_reviews');
      const existingReviews = reviewsJsonString ? JSON.parse(reviewsJsonString) : [];
      const updatedReviews = existingReviews.map((localReview: LocalReview) => {
        if (localReview.id === reviewId) {
          // Update the status to "submitted"
          return { ...localReview, status: 'submitted' };
        }
        return localReview;
      });
      const updatedReviewsJsonString = JSON.stringify(updatedReviews);
      localStorage.setItem('kc_reviews', updatedReviewsJsonString);

      ReactGA.event({
        category: "New Review Form",
        action: "Successful Submit Review",
        label: reviewId,
      });

      // navigate to the submitted page
      navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}/new-review/${reviewId}/submitted`)
    })
    .catch((error) => {
      ReactGA.event({
        category: "New Review Form",
        action: "Failed Submit Review",
        label: reviewId,
      });
      // TODO: error handling
      console.error('Failed to create new review', error);
      if (error.status === 409) {
        // showDuplicateDialog(true);
        Error("Sentry Test Error");
      } else {
        // showErrorDialog(true);
      }
    })

  }

  const handleSubmit = async (values: any, bag: FormikHelpers<any>) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
      
      if (isLastStep) {
        return onSubmit(values, bag);
      } else {
        const patchReview: UpdatePublicReviewApiArg = {
          reviewId: reviewId || "",
          createUpdateReview: {
            establishment_id: establishmentId,
            status: "draft",
            review_page: activeStep + 1,
            overall_rating: values.overall_rating,
            last_year_employed: values.last_year_of_employment,
            review: values.review,
            role: values.role,
            tip_pool_house: values.tip_pool,
            wage: parseFloat(values.base_wage),
            wage_type: values.wage_type,
            avg_tip_take: parseFloat(values.avg_tips_per_shift),
            avg_length_of_shift: typeof values.avg_length_of_shift === 'number' ? values.avg_length_of_shift : undefined,
            avg_shifts_per_week: typeof values.avg_shifts_per_week === 'number' ? values.avg_shifts_per_week : undefined,
            avg_busyness: values.avg_busyness,
            schedule_flexibility: values.schedule_flexibility,
            employee_satisfaction: values.employee_satisfaction,
            management_evaluation: values.management_evaluation,
            benefits: values.benefits,
          }
        };

        updateNewReview(patchReview)
        .unwrap()
        .then((payload) => {
          ReactGA.event({
            category: "New Review Form",
            action: "Successful Update Review",
            label: reviewId,
          });
        })
        .catch((error) => {
          // TODO: error handling
          ReactGA.event({
            category: "New Review Form",
            action: "Failed Update Review",
            label: reviewId,
          });
          console.error('Failed to create new review', error);
          if (error.status === 409) {
            // showDuplicateDialog(true);

          } else {
            // showErrorDialog(true);
          }
        })
      }
      bag.setTouched({});
      handleNext(values);
    }
  };

  // update the active step or page based on the review status
  // and the review page
  useEffect(() => {
    if (loadingReview === false && review?.status === "draft") {
      setSnapshot({
        overall_rating: review?.overall_rating || 0,
        last_year_of_employment: review?.last_year_employed || new Date().getFullYear(),
        review: review?.review || '',
        role: review?.role || '',
        tip_pool: review?.tip_pool_house || false,
        base_wage: review?.wage || '',
        wage_type: review?.wage_type || 'hourly',
        avg_tips_per_shift: review?.avg_tip_take || '',
        avg_length_of_shift: review?.avg_length_of_shift || '',
        avg_shifts_per_week: review?.avg_shifts_per_week || '',
        avg_busyness: review?.avg_busyness || 3,
        schedule_flexibility: review?.schedule_flexibility || 3,
        employee_satisfaction: review?.employee_satisfaction || 3,
        management_evaluation: review?.management_evaluation || 3,
        benefits: review?.benefits || [],
      });
      setActiveStep(review?.review_page || 0);
    } else if (review?.status === "submitted") {
      navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}/new-review/${reviewId}/submitted`)
    }
  }, [review, loadingReview])

  useEffect(() => {
    if (errorReview && (errorReview as FetchBaseQueryError).status === 403) {
      navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}/new-review/${reviewId}/submitted`)
    }
  }, [errorReview, isErrorReview])

  useEffect(() => {
    if (querySearch.get("existing") && querySearch.get("existing") === "true") {
      setIsDuplicateAlert(true);
    }
  }, [querySearch]);

  if (loadingReview || loadingEstablishment) {
    return (
      <ResponsiveContainer>
        <Container sx={{ paddingTop: "70px", paddingBottom: "70px"}}>
          <Box sx={{ display: "flex", justifyContent: "center", height: "60vh"}}>
            <Stack 
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="primary" variant="indeterminate" />
              <Typography variant='body1' color='text.secondary' align='center' sx={{ padding: '60px', fontWeight: 'bold' }}>Warming up the grill, hang tight...</Typography>
            </Stack>
          </Box>
        </Container>
      </ResponsiveContainer>
    )
  }

  if (isErrorReview || isErrorEstablishment) {
    return (
      <ResponsiveContainer>
        <Container sx={{ paddingTop: "70px", paddingBottom: "70px"}}>
          <Box sx={{ display: "flex", justifyContent: "center", height: "60vh"}}>
            <Stack 
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant='body1' color='text.secondary' align='center' sx={{ padding: '60px', fontWeight: 'bold' }}>Oops! Something went wrong...</Typography>
            </Stack>
          </Box>
        </Container>
      </ResponsiveContainer>
    )
  }

  return (
    <ResponsiveContainer>
      <ExitDialog isOpen={exitDialogOpen} onClose={() => setExitDialogOpen(false)} />
      <Container sx={{ 
        paddingTop: "70px", 
        paddingBottom: "70px",
        [theme.breakpoints.down('md')]: {
          padding: "20px 0px",
        },
      }}>
        {isDuplicateAlert && (
        <Alert severity="info" onClose={() => 
          {
            setIsDuplicateAlert(false);
            querySearch.delete("existing");
            setQuerySearch(querySearch);
          }
        } sx={{
          margin: "20px 0px",
        }}>
          Starting from where you left off...
        </Alert>
      )}
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "20px",
            padding: "30px 30px 30px 30px",
            [theme.breakpoints.down('md')]: {
              padding: "0px",
            },
          }}
        >
          <Typography 
            variant="h3" 
            color="text.secondary" 
            align='center'
            sx={{ 
              padding: "20px 0px 20px 0px",
              fontWeight: 'bold',
              [theme.breakpoints.down('md')]: {
                fontSize: "24px",
              },
            }}
          >{establishment?.name || ""}</Typography>
          <Box sx={{
            display: "flex",
            justifyContent: "center",

          }}>
            <Stack 
              direction="row" 
              spacing={1} 
              alignItems="center"
              justifyContent="center"
              sx={{
                padding: "10px",
                backgroundColor: "#E5F5EA",
                borderRadius: "12px",
                margin: "5px 24px",
                width: "80vw",
              }}
            >
              <GreenStar /> 
              <Typography 
                variant="body1" 
                color="text.secondary" 
                align='center'
                sx={{ 
                  [theme.breakpoints.down('md')]: {
                    fontSize: "14px",
                  },
                }}
              >All reviews are completely anonymous.</Typography>
            </Stack>
          </Box>
        <StyleTabs 
          variant="fullWidth" 
          centered 
          value={activeStep} 
          aria-label="simple tabs example"
          sx={{
            [theme.breakpoints.down('md')]: {
              padding: "0px",
            },
          }}
        >
        {steps.map((label, index) => (
          <StyleTab disabled label={label} key={index} />
        ))}
        </StyleTabs>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Formik
              initialValues={snapshot}
              onSubmit={handleSubmit}
              validationSchema={step.props.validationSchema}
              enableReinitialize
            >
              {formik => (
                <Form>
                  {getStep(formik)}
                  {/* {formik.errors && <Typography color="error">{formik.errors.review}</Typography>} */}
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    padding: '2px 20px' }}>
                    <KCButtonBase
                      color="inherit"
                      onClick={handleBack}
                      sx={{ mr: 1, width: '158px' }}
                      kcvariant='outlined'
                    >
                      {activeStep === 0 ? 'Exit' : 'Back'}
                    </KCButtonBase>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <KCButtonBase type="submit" disabled={formik.isSubmitting} sx={{  width: '158px'}}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </KCButtonBase>
                  </Box>
                </Form>
              )}
            </Formik>
          </React.Fragment>
        )}
        </Box>
      </Container>
    </ResponsiveContainer>
  );
};

export default NewReviewPage;
