import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, Drawer, DrawerProps, IconButton, Stack, Typography, styled } from '@mui/material';

import { ReactComponent as ShareFacebook } from '../../assets/svg/Socials/share_facebok.svg';
import { ReactComponent as ShareTwitter } from '../../assets/svg/Socials/share_twitter.svg';
import { ReactComponent as ShareReddit } from '../../assets/svg/Socials/share_reddit.svg';
import { ReactComponent as ShareWhatsApp } from '../../assets/svg/Socials/share_whats.svg';
import { ReactComponent as ShareText } from '../../assets/svg/Socials/share_text.svg';
import { ReactComponent as ShareEmail } from '../../assets/svg/Socials/share_email.svg';
import { ReactComponent as ExitIcon } from '../../assets/svg/icons/exit_icon_dark.svg';
import KCButtonBase from '../Button';

import {
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { useNavigate } from 'react-router-dom';


interface KCShareDrawerProps extends DrawerProps {
  // Add any additional props you need for your custom dialog
  // For example, you can add a custom title or content
  customTitle: string;
  currentUrl: string;
}


const StyledDrawer = styled(Drawer)<DrawerProps>(({ theme }) => ({
  '& .MuiDrawer-paper': {
    borderRadius: '32px 32px 0px 0px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    minHeight: '10vh',
    padding: '50px 24px',
  },
}));

const KCShareDrawer: React.FC<KCShareDrawerProps> = ({
  customTitle,
  currentUrl,
  ...drawerProps
}) => {

  const [copyState, setCopyState] = useState('Copy')

  async function copyToClip() {
    await navigator.clipboard.writeText(currentUrl);
    setCopyState('Copied')
  }

  return (
    <StyledDrawer {...drawerProps}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'flex-end',
        }} 
      >
        <IconButton onClick={() => {
          setCopyState('Copy')
          drawerProps.onClose && drawerProps.onClose({}, 'backdropClick')
        }
        }>
          <ExitIcon />
        </IconButton>
      </Box>
      <Typography variant="h3" color="text.secondary" textAlign="center" sx={{
        marginBottom: '20px'
      }}>
          Share {customTitle}
      </Typography>
        <Stack direction='row' spacing={6} useFlexGap flexWrap="wrap" justifyContent="center">
          <Stack direction='column' spacing={2}>
            <FacebookShareButton url={currentUrl}>
              <ShareFacebook />
            </FacebookShareButton>
           <Typography textAlign="center" variant="body2" color="text.secondary">Facebook</Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <TwitterShareButton url={currentUrl}>
              <ShareTwitter />
            </TwitterShareButton>
           <Typography textAlign="center" variant="body2" color="text.secondary">X</Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <RedditShareButton url={currentUrl}>
              <ShareReddit />
            </RedditShareButton>
           <Typography textAlign="center" variant="body2" color="text.secondary">Reddit</Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <WhatsappShareButton url={currentUrl}>
              <ShareWhatsApp />
            </WhatsappShareButton>
           <Typography textAlign="center" variant="body2" color="text.secondary">WhatsApp</Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <IconButton href={`sms:[phone number]&body=Check out this review! ${currentUrl}`}>
              <ShareText width="70px" height="73.5px" />
            </IconButton>
           <Typography textAlign="center" variant="body2" color="text.secondary">Text Message</Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <EmailShareButton url={currentUrl}>
              <ShareEmail />
            </EmailShareButton>
           <Typography textAlign="center" variant="body2" color="text.secondary">Email</Typography>
          </Stack>
          <KCButtonBase 
            color='primary'
            size='large'
            onClick={copyToClip}
            fullWidth
          >
            { copyState == 'Copied' ? 'Copied link!' : 'Copy link to clipboard'}
          </KCButtonBase>
        </Stack>
    </StyledDrawer>
  );
};

export default KCShareDrawer;