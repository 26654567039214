import { Box, CircularProgress, Container, Drawer, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {useCreatePublicEstablishmentMutation, CreatePublicEstablishmentApiArg } from "../../store/kcApi";

export const ResponsiveContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Box>
        {children}
      </Box>
    );
  }

  return (
    <Box
    sx={{ backgroundColor: "#FBF8F5"}}
    >
      {children}
    </Box>
  );
};

const NewEstablishmentRedirect: React.FC = () => {

  const [querySearch, setQuerySearch] = useSearchParams();

  const [createEst] = useCreatePublicEstablishmentMutation();

  let navigate = useNavigate();

  useEffect(() => {

    const googlePlaceId = querySearch.get("gid");

    if (!googlePlaceId) {
      navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/`);
      return
    }

    const newEst: CreatePublicEstablishmentApiArg = {
      createEstablishment: {
        google_place_id: googlePlaceId,
      },
    }
  
    createEst(newEst)
      .unwrap()
      .then((payload) => {
        navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${payload.id}/new-review`)
        return
      })
      .catch((error) => {
        // TODO: error handling
        console.error('Failed to create new establishment', error);
        // navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/write-review`);
    });


  }, [querySearch])


  return (
    <ResponsiveContainer>
      <Container sx={{ paddingTop: "70px", paddingBottom: "70px"}}>
        <Box sx={{ display: "flex", justifyContent: "center", height: "60vh"}}>
          <Stack 
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="primary" variant="indeterminate" />
            <Typography variant='body1' color='text.secondary' align='center' sx={{ padding: '60px', fontWeight: 'bold' }}>Warming up the grill and flipping the burgers! Hang tight, your table is almost ready...</Typography>
          </Stack>
        </Box>
      </Container>
    </ResponsiveContainer>
  )
}

export default NewEstablishmentRedirect;